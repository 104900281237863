import { Activity, Next, Action, Dispatch } from 'botframework-webchat';
import { IAskHRRequest } from '../Activities/AskHRFormActivity/AskHRForm/AskHRForm.types';
import { ActionTypes, ActivityType } from '../AskHRBot.types';
import { AskHrSupportApiService, ICaseDataUploadRequest } from '../APIs';
import { IAppContext } from '../Models/IAppContext';
import {
    TelemetryEventName,
    UsageEventType,
    UsageCapabilityName,
    UsageEventName,
    UsageSubCapabilityName,
} from '../Resources';
import { TicketUploadUID } from '../../Shared/Constants';
import { AskHRFormContext } from '../Context/AskHRFormContext/AskHRFormContext';

export function shouldTriggerUploadConversationHistoryAction(activity: Activity) {
    return (
        activity.name === 'handoff.initiate' &&
        activity.type === ActivityType.Event &&
        activity.from.role === 'bot' &&
        activity.attachments.length != 0 &&
        //activity.attachments[0].content.activities.length != 0
        //updating condition to satisfy both PVA classic and v2.0  
        activity.attachments[0].content
    );
}

export async function uploadConversationAction(
    appContext: IAppContext,
    dispatch: Dispatch,
    activity: Activity,
    next: Next,
    action: Action
) {
    const { telemetryClient, authClient } = appContext;

    let attachmentsBlob = null;
    let _content = activity?.attachments[0].content;

    if(Array.isArray(_content)){ 
        //PVA2.0
        attachmentsBlob = _content;
    }
    else if(_content.hasOwnProperty('activities') && Array.isArray(_content.activities) && _content.activities.length > 0){
        //PVA1.0 Classic
        attachmentsBlob = _content.activities;
    }

    // const loggedInUser = activity.value.flow_output_hrcore_upn;
    const askHRCategoryId = activity.value.var_askHRCategoryId ? activity.value.var_askHRCategoryId : '';
    const user = await authClient.getUser();
    const ticketRequest: IAskHRRequest = JSON.parse(activity.value.varAskHRRequest);
    const uploadRequestUID = sessionStorage.getItem(TicketUploadUID);
    telemetryClient.trackCustomEvent({
        name: TelemetryEventName.AskHRTicketUploadInitiated,
        properties: {
            UsageEventType: UsageEventType.SystemAction,
            UsageCapabilityName: UsageCapabilityName.AskHRBot,
            UsageSubCapabilityName: UsageSubCapabilityName.ChatHistory,
            UsageEventName: UsageEventName.DependencyRequested,
            requestUploadUID: ticketRequest.uploadUID,
            localTktUploadUID: uploadRequestUID

        },
    });
    if (uploadRequestUID && uploadRequestUID !== null && uploadRequestUID === ticketRequest.uploadUID) {
        const caseDataUploadRequest: ICaseDataUploadRequest = {
            conversationId: activity.conversation.id,
            blobFileType: 'application/json',
            blobContent: JSON.stringify({
                loggedInUser: user.email,
                conversationId: activity.conversation.id,
                dateTime: new Date().toISOString(),
                transcript: attachmentsBlob,
                botHost: activity.value.botHost,
                ticketTitle: ticketRequest.subject,
                ticketDescription: ticketRequest.description,
                ticketAttachments: ticketRequest.attachments,
                highPriority: ticketRequest.highPriority,
                topic: ticketRequest.topic,
                askHRCategoryId: askHRCategoryId,
                ticketIdentifier: ''
            }),
        };
        
        console.log(caseDataUploadRequest.conversationId)

        telemetryClient.trackCustomEvent({
            name: TelemetryEventName.ConversationHistoryUploadStarted,
            properties: {
                UsageEventType: UsageEventType.SystemAction,
                UsageCapabilityName: UsageCapabilityName.AskHRBot,
                UsageSubCapabilityName: UsageSubCapabilityName.ChatHistory,
                UsageEventName: UsageEventName.DependencyRequested,
            },
        });

        const uploaded = await AskHrSupportApiService.uploadCaseData(caseDataUploadRequest);
        AskHRFormContext.setTicketUploading(false); //update loading to complete
        sessionStorage.setItem(TicketUploadUID, null);
        if (uploaded) {
            AskHRFormContext.setUploadResponseStatus(true); //update response status
            telemetryClient.trackCustomEvent({
                name: TelemetryEventName.ConversationHistoryUploaded,
                properties: {
                    UsageEventType: UsageEventType.SystemAction,
                    UsageCapabilityName: UsageCapabilityName.AskHRBot,
                    UsageSubCapabilityName: UsageSubCapabilityName.ChatHistory,
                    UsageEventName: UsageEventName.DependencyRequestSucceeded,
                },
            });

            dispatch({
                type: ActionTypes.PUBLISH_CHATHISTORY,
                payload: uploaded,
            });
        } else {
            AskHRFormContext.setUploadResponseStatus(false);
            telemetryClient.trackCustomEvent({
                name: TelemetryEventName.ConversationHistoryUploadFailed,
                properties: {
                    UsageEventType: UsageEventType.SystemAction,
                    UsageCapabilityName: UsageCapabilityName.AskHRBot,
                    UsageSubCapabilityName: UsageSubCapabilityName.ChatHistory,
                    UsageEventName: UsageEventName.DependencyRequestFailed,
                },
            });
        }
    }

    return next(action);
}
