import { useEffect, useContext } from 'react';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { AskHrSupportApiService, IAskHrSupportApiConfig } from '../APIs';

export function useAskHrSupportApi(): void {
    const { telemetryClient, authClient } = useContext(ComponentContext);

    const storageConfig: IAskHrSupportApiConfig = {
        apiBaseUri: __ASHKR_SUPPORT_API_BASE_URL__,
        apiResourceId: __ASKHR_SUPORT_API_RESOURCE_ID__
    };

    useEffect(() => {
        AskHrSupportApiService.initialize(storageConfig, telemetryClient, authClient);
    }, []);
}
