import * as React from 'react';
import * as Styled from './AskHRFormAttachmentItem.styled';
import { IAskHRFormAttachmentItemProps } from './AskHRFormAttachmentItem.types';

export function AskHRFormAttachmentItem(props: IAskHRFormAttachmentItemProps): React.ReactElement {
    const { file, onDelete } = props;

    const handlePressed = () => {
        onDelete(file);
    };

    return (
        <li>
            <Styled.Root
                aria-roledescription={file.name + ' button'}
                aria-label="delete"
                onClick={handlePressed}
            >
                <Styled.DeleteIcon />
                {file.name}{' '}
                <Styled.Size>({(file.bufferLength / 1048576).toFixed(2)} mb)</Styled.Size>
            </Styled.Root>
        </li>
    );
}
