import * as React from 'react';
import { Icon, IconType } from 'office-ui-fabric-react/lib/Icon';
import { registerIcons } from 'office-ui-fabric-react/lib/Styling';

export function initializeCustomIcons(): void {
    registerIcons({
        icons: {
            HRWeb: (
                <Icon
                    imageProps={{
                        src: '/assets/hr.svg',
                        width: 20,
                    }}
                    iconType={IconType.Image}
                />
            ),
        },
    });
}
