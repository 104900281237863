import { useEffect, useCallback, useContext } from 'react';
import { ConversationContext } from '../Context/ConversationContext';
import {
    ConversationEvent,
    IConversationContext,
} from '../Context/ConversationContext/ConversationContext.types';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import {
    UsageCapabilityName,
    UsageEventName,
    UsageEventType,
    UsageSubCapabilityName,
} from '../Resources';

const id = 'useConversationTelemetry';

export function useConversationTelemetry(): void {
    const { telemetryClient } = useContext(ComponentContext);

    const botUsageData = {
        UsageEventName: UsageEventName.WebChatMessage,
        UsageEventType: UsageEventType.SystemAction,
        UsageCapabilityName: UsageCapabilityName.WebChat,
        UsageSubCapabilityName: UsageSubCapabilityName.BotWebChatMessage,
    };
    const userUsageData = {
        UsageEventName: UsageEventName.WebChatMessage,
        UsageEventType: UsageEventType.UserAction,
        UsageCapabilityName: UsageCapabilityName.WebChat,
        UsageSubCapabilityName: UsageSubCapabilityName.UserWebChatMessage,
    };

    const trackInitialized = useCallback(
        (prev: IConversationContext, next: IConversationContext): void => {
            const { event, lastActivityDetails, ...other } = next;

            telemetryClient.trackCustomEvent({
                name: event,
                properties: {
                    UsageEventName: UsageEventName.WebChatMessage,
                    UsageEventType: UsageEventType.SystemAction,
                    UsageCapabilityName: UsageCapabilityName.WebChat,
                    UsageSubCapabilityName: UsageSubCapabilityName.Initialization,
                    ...other,
                    ...lastActivityDetails,
                },
            });
        },
        []
    );

    const trackBotConversationInitiation = useCallback(
        (prev: IConversationContext, next: IConversationContext): void => {
            const { event, lastActivityDetails, ...other } = next;
            const timeSinceStart = next.lastBotActionTime.getTime() - next.startTime.getTime();

            telemetryClient.trackCustomEvent({
                name: event,
                properties: {
                    ...botUsageData,
                    ...other,
                    ...lastActivityDetails,
                    duration: timeSinceStart,
                },
            });

            telemetryClient.trackDependencyData({
                name: event,
                id: event,
                duration: timeSinceStart,
                responseCode: 200,
                properties: {
                    ...botUsageData,
                    ...other,
                    ...lastActivityDetails,
                },
            });
        },
        []
    );

    const trackBotRespondedToUser = useCallback(
        (prev: IConversationContext, next: IConversationContext): void => {
            const { event, lastActivityDetails, ...other } = next;
            const timeSinceUserAction =
                next.lastBotActionTime.getTime() - next.lastUserActionTime.getTime();

            telemetryClient.trackCustomEvent({
                name: event,
                properties: {
                    ...botUsageData,
                    ...other,
                    ...lastActivityDetails,
                    duration: timeSinceUserAction,
                },
            });

            telemetryClient.trackDependencyData({
                name: event,
                id: event,
                duration: timeSinceUserAction,
                responseCode: 200,
                properties: {
                    ...botUsageData,
                    ...other,
                    ...lastActivityDetails,
                },
            });
        },
        []
    );

    const trackBotSentAdditionalMessage = useCallback(
        (prev: IConversationContext, next: IConversationContext): void => {
            const { event, lastActivityDetails, ...other } = next;
            const timeSinceLastBotAction =
                next.lastBotActionTime.getTime() - prev.lastBotActionTime.getTime();

            telemetryClient.trackCustomEvent({
                name: event,
                properties: {
                    ...botUsageData,
                    ...other,
                    ...lastActivityDetails,
                    duration: timeSinceLastBotAction,
                },
            });

            telemetryClient.trackDependencyData({
                name: event,
                id: event,
                duration: timeSinceLastBotAction,
                responseCode: 200,
                properties: {
                    ...botUsageData,
                    ...other,
                    ...lastActivityDetails,
                },
            });
        },
        []
    );

    const trackUserConversationInitiation = useCallback(
        (prev: IConversationContext, next: IConversationContext): void => {
            const { event, lastActivityDetails, ...other } = next;
            const timeSinceStart = next.lastUserActionTime.getTime() - next.startTime.getTime();

            telemetryClient.trackCustomEvent({
                name: event,
                properties: {
                    ...userUsageData,
                    ...other,
                    ...lastActivityDetails,
                    duration: timeSinceStart,
                },
            });
        },
        []
    );

    const trackUserRespondedToBot = useCallback(
        (prev: IConversationContext, next: IConversationContext): void => {
            const { event, lastActivityDetails, ...other } = next;
            const timeSinceBotAction =
                next.lastUserActionTime.getTime() - next.lastBotActionTime.getTime();

            telemetryClient.trackCustomEvent({
                name: event,
                properties: {
                    ...userUsageData,
                    ...other,
                    ...lastActivityDetails,
                    duration: timeSinceBotAction,
                },
            });
        },
        []
    );

    const trackUserSentAdditionalMessage = useCallback(
        (prev: IConversationContext, next: IConversationContext): void => {
            const { event, lastActivityDetails, ...other } = next;
            const timeSinceLastUserAction =
                next.lastUserActionTime.getTime() - prev.lastUserActionTime.getTime();

            telemetryClient.trackCustomEvent({
                name: event,
                properties: {
                    ...userUsageData,
                    ...other,
                    ...lastActivityDetails,
                    duration: timeSinceLastUserAction,
                },
            });
        },
        []
    );

    useEffect(() => {
        ConversationContext.subscribe(id, ConversationEvent.Initialized, trackInitialized);
        ConversationContext.subscribe(
            id,
            ConversationEvent.BotInitiatedConversation,
            trackBotConversationInitiation
        );
        ConversationContext.subscribe(
            id,
            ConversationEvent.BotRespondedToUser,
            trackBotRespondedToUser
        );
        ConversationContext.subscribe(
            id,
            ConversationEvent.BotSentAdditionalMessage,
            trackBotSentAdditionalMessage
        );
        ConversationContext.subscribe(
            id,
            ConversationEvent.UserInitiatedConversation,
            trackUserConversationInitiation
        );
        ConversationContext.subscribe(
            id,
            ConversationEvent.UserRespondedToBot,
            trackUserRespondedToBot
        );
        ConversationContext.subscribe(
            id,
            ConversationEvent.UserSentAdditionalMessage,
            trackUserSentAdditionalMessage
        );

        return () => {
            ConversationContext.unsubscribe(id, ConversationEvent.Initialized);
            ConversationContext.unsubscribe(id, ConversationEvent.BotInitiatedConversation);
            ConversationContext.unsubscribe(id, ConversationEvent.BotRespondedToUser);
            ConversationContext.unsubscribe(id, ConversationEvent.BotSentAdditionalMessage);
            ConversationContext.unsubscribe(id, ConversationEvent.UserInitiatedConversation);
            ConversationContext.unsubscribe(id, ConversationEvent.UserRespondedToBot);
            ConversationContext.unsubscribe(id, ConversationEvent.UserSentAdditionalMessage);
        };
    }, []);
}
