class TextInputDisabler {
    public disable(placeHolderText?: string): void {
        const textInput = this.getTextInput();
        if (!textInput) return;
        if (!placeHolderText) placeHolderText = 'Please wait...';
        textInput.setAttribute('disabled', 'true');
        textInput.setAttribute('placeholder', placeHolderText);

        const sendButton = this.getSendButton();
        if (!sendButton) return;

        sendButton.setAttribute('disabled', 'true');
    }

    public enable(): void {
        const textInput = this.getTextInput();
        if (!textInput) return;

        textInput.removeAttribute('disabled');
        textInput.setAttribute('placeholder', 'Type your message');
        textInput.focus();

        const sendButton = this.getSendButton();
        if (!sendButton) return;

        sendButton.removeAttribute('disabled');
    }

    private getTextInput(): HTMLInputElement {
        return document.querySelector('[data-id="webchat-sendbox-input"]');
    }

    private getSendButton(): HTMLButtonElement {
        return document.querySelector('.webchat__send-button');
    }
}

const textInputDisabler = new TextInputDisabler();
export { textInputDisabler as TextInputDisabler };
