export enum UsageEventType {
    UserAction = 'UserAction',
    SystemAction = 'SystemAction',
}

export enum TelemetryEventName {
    UserLogin = 'UserLogin',
    BotToggleButtonClicked = 'BotToggleButtonClicked',
    DirectLineTokenRequested = 'DirectLineTokenRequested',
    DirectLineTokenReceived = 'DirectLineTokenReceived',
    DirectLineTokenRequestFailed = 'DirectLineTokenRequestFailed',
    UserPhotoRequested = 'UserPhotoRequested',
    UserPhotoReceived = 'UserPhotoReceived',
    UserPhotoRequestFailed = 'UserPhotoRequestFailed',
    DirectLineConnectionCreated = 'DirectLineConnectionCreated',
    DirectLineConnectionFailed = 'DirectLineConnectionFailed',
    CheckCompanyCodeEligibilityStarted = 'CheckCompanyCodeEligibilityStarted',
    CheckCompanyCodeEligibilityFailed = 'CheckCompanyCodeEligibilityFailed',
    CheckCompanyCodeEligibilitySucceeded = 'CheckCompanyCodeEligibilitySucceeded',
    UnknownBotResponseType = 'UnknownBotResponseType',
    EmptyBotResponse = 'EmptyBotResponse',
    FlowResponseReceived = 'FlowResponseReceived',
    UserInitiatedConversation = 'UserInitiatedConversation',
    BotInitiatedConversation = 'BotInitiatedConversation',
    UserRespondedToBot = 'UserRespondedToBot',
    BotRespondedToUser = 'BotRespondedToUser',
    UserSentAdditionalMessage = 'UserSentAdditionalMessage',
    BotSentAdditionalMessage = 'BotSentAdditionalMessage',
    ConversationHistoryUploadStarted = 'ConversationHistoryUploadStarted',
    ConversationHistoryUploaded = 'ConversationHistoryUploaded',
    ConversationHistoryUploadFailed = 'ConversationHistoryUploadFailed',
    PVATokenExchangeRequested = 'PVATokenExchangeRequested',
    PVATokenExchangeRetried = 'PVATokenExchangeRetried',
    PVATokenExchangeFailed = 'PVATokenExchangeFailed',
    PVATokenExchangeSucceeded = 'PVATokenExchangeSucceeded',
    SessionTimeOutModalShown = 'SessionTimeOutModalShown',
    SessionRefreshRequested = 'SessionRefreshRequested',
    UserFeedbackProvided = 'UserFeedbackProvided',
    AdhocFlow = 'AdhocFlow',
    BotSecurity = 'BotSecurity',
    AskHRTicketFormLoad = 'AskHRTicketFormLoad',
    AskHRTicketSubmitClicked = 'AskHRTicketSubmitClicked',
    AskHRTicketUploadInitiated = 'AskHRTicketUploadInitiated',
    AskHRTicketSubmitted = 'AskHRTicketSubmitted',
    AskHRTicketFailed = 'AskHRTicketFailed',
    AskHrSupportApiServiceInitialized = 'AskHrSupportApiServiceInitialized',
    AskHrSupportApiServiceNotInitialized = 'AskHrSupportApiServiceNotInitialized',
    AskHrSupportApiOperationSucceeded = 'AskHrSupportApiOperationSucceeded',
    AskHrSupportApiOperationFailed = 'AskHrSupportApiOperationFailed',
}


export enum UsageEventName {
    PageLoad = 'PageLoad',
    ButtonClick = 'ButtonClick',
    LinkClick = 'LinkClick',
    AccessCheck = 'AccessCheck',
    DependencyInitialized = 'DependencyInitialized',
    DependencyInitializationFailed = 'DependencyInitializationFailed',
    DependencyRequested = 'DependencyRequested',
    DependencyRetried = 'DependencyRetried',
    DependencyRequestFailed = 'DependencyRequestFailed',
    DependencyRequestSucceeded = 'DependencyRequestSucceeded',
    ValidationFailed = 'ValidationFailed',
    WebChatMessage = 'WebChatMessage',
    AdhocWorkFlowInitiation = 'AdhocWorkFlowInitiation',
    Session = 'Session',
    AdhocWorkFlowNotFoundBestBet = 'AdhocWorkFlowNotFoundBestBet',
    UserAuthorization = 'UserAuthorization',
    BotSecurity = 'BotSecurity',
    CheckBoxChanged = "CheckBoxChanged"
}

export enum UsageCapabilityName {
    Unmapped = 'unmapped',
    Platform = 'Platform',
    AskHRBot = 'AskHRBot',
    PVADirectLine = 'PVADirectLine',
    MicrosoftGraph = 'MicrosoftGraph',
    CompanyCodeEligibilityCheck = 'CompanyCodeEligibilityCheck',
    WebChat = 'WebChat',
    AskHrSupportApi = 'AskHrSupportApi',
}

export enum UsageSubCapabilityName {
    Unmapped = 'unmapped',
    ShowBot = 'Show Bot',
    HideBot = 'Hide Bot',
    Get = 'Get',
    Post = 'Post',
    CompanyCodeEligible = 'CompanyCodeEligible',
    CompanyCodeIneligible = 'CompanyCodeIneligible',
    BotResponse = 'BotResponse',
    Initialization = 'Initialization',
    ChatHistory = 'ChatHistory',
    Upload = 'Upload',
    PVATokenExchange = 'PVATokenExchange',
    BotWebChatMessage = 'BotWebChatMessage',
    UserWebChatMessage = 'UserWebChatMessage',
    SessionTimeoutModal = 'SessionTimeoutModal',
    UserAction = 'UserAction',
    SessionRefresh = 'SessionRefresh',
    Feedback = 'Feedback',
    UserAdhocQuestion = 'UserAdhocQuestion',
    UserAdhocQuestionNotInBestBet = 'UserAdhocQuestionNotInBestBet',
    UserUnAuthorized = 'UserUnAuthorized',
    AskHRTicket = 'AskHRTicket',
    UploadCaseAttachments = 'UploadCaseAttachments',
    UploadCaseData = 'UploadCaseData',
}

export interface IHeartMatrix {
    UsageEventName: UsageEventName;
    UsageEventType: UsageEventType;
    UsageCapabilityName: UsageCapabilityName;
    UsageSubCapabilityName: UsageSubCapabilityName;
}
