import { css } from 'styled-components';
import { Color, Font } from '../../Resources';

export const Root = styled.p.attrs({ className: 'custom-response' })`
    padding: 10px;
    font-family: ${Font.Default};
    line-height: 1.3;
`;

export const NoData = styled.p`
    padding: 0;
    margin: 0 !important;
`;

export const List = styled.ul`
    padding: 0;
    list-style: none;
`;

export const ListItem = styled.li`
    margin-bottom: 32px !important;

    &:last-of-type {
        margin-bottom: 0 !important;
    }
`;

const Title = css`
    padding: 0;
    margin: 0;
    margin-bottom: 16px;
    display: block;
    font-weight: 600;
    cursor: pointer;
`;

export const TitleText = styled.p`
    ${Title};
`;

export const TitleLink = styled.a`
    ${Title};

    color: ${Color.Primary};
    text-decoration: underline;
`;

export const ListItemDescription = styled.p`
    margin-bottom: 0 !important;
`;
