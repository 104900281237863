export const Root = styled.div`
    margin-bottom: 32px;
`;

export const HiddenInput = styled.input`
    display: none;
`;

export const AttachmentHint = styled.p`
    font-size: 12px;
    margin-bottom: 12px;
    color: #646464;
`;

export const AttachmentList = styled.ul`
    padding-left: 0;
    margin-left: 0;
`;

export const ErrorMessage = styled.p`
    font-size: 12px;
    font-weight: 400;
    color: rgb(164, 38, 44);
    margin-top: 12px;
`;
