class Spinner {
    private spinner: HTMLDivElement | null = null;

    public show(): void {
        if (!this.spinner) this.createSpinnerElement();

        this.getChatContainer()?.after(this.spinner);
    }

    public hide(): void {
        if (!this.spinner) return;

        const parent = this.spinner.parentNode;
        if (!parent) return;

        parent.removeChild(this.spinner);
    }

    private createSpinnerElement() {
        this.spinner = document.createElement('div');
        this.spinner.setAttribute('aria-hidden', 'true');
        this.spinner.setAttribute('class', 'css-1qyo5rb');
        this.spinner.innerHTML = `
            <div style="margin-left: 16px">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width="30px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                    <rect x="0" y="10" width="5" height="10" fill="rgb(0, 120, 215)" opacity="0.2">
                        <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.8s" repeatCount="indefinite" />
                        <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.8s" repeatCount="indefinite" />
                        <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.8s" repeatCount="indefinite" />
                    </rect>
                    <rect x="10" y="10" width="5" height="10" fill="rgb(0, 120, 215)"  opacity="0.2">
                        <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.2s" dur="0.8s" repeatCount="indefinite" />
                        <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.2s" dur="0.8s" repeatCount="indefinite" />
                        <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.2s" dur="0.8s" repeatCount="indefinite" />
                    </rect>
                    <rect x="20" y="10" width="5" height="10" fill="rgb(0, 120, 215)"  opacity="0.2">
                        <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.4s" dur="0.8s" repeatCount="indefinite" />
                        <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.4s" dur="0.8s" repeatCount="indefinite" />
                        <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.4s" dur="0.8s" repeatCount="indefinite" />
                    </rect>
                </svg>
            </div>
        `;
    }

    private getChatContainer(): HTMLUListElement {
        return document.querySelector('.webchat__basic-transcript__focus-indicator');
    }
}

const spinner = new Spinner();
export { spinner as Spinner };
