import { ComponentContext } from '@employee-experience/common';
import { useEffect, useContext } from 'react';
import {
    TelemetryEventName,
    UsageCapabilityName,
    UsageEventName,
    UsageEventType,
    UsageSubCapabilityName,
} from '../Resources';

type CustomNodeType = Node & ParentNode & HTMLElement;

export function useLinkTelemetry(): void {
    const { telemetryClient } = useContext(ComponentContext);

    const isFromCustomResponse = (element: CustomNodeType, classname: string): boolean => {
        if (element?.className?.split(' ')?.indexOf(classname) >= 0) return true;

        return (
            element.parentNode &&
            isFromCustomResponse(element.parentNode as CustomNodeType, classname)
        );
    };

    useEffect(() => {
        const handleDocumentClicked = (e: MouseEvent) => {
            if (isFromCustomResponse(e.target as CustomNodeType, 'custom-response')) {
                const { innerText } = e.target as HTMLElement;
                const href = (e.target as HTMLElement)?.getAttribute('href');

                telemetryClient.trackCustomEvent({
                    name: UsageEventName.LinkClick,
                    properties: {
                        UsageEventName: UsageEventName.LinkClick,
                        UsageEventType: UsageEventType.UserAction,
                        UsageCapabilityName: UsageCapabilityName.WebChat,
                        UsageSubCapabilityName: UsageSubCapabilityName.UserAction,
                        link: href,
                        linkText: innerText,
                    },
                });
            }
        };

        document.addEventListener('click', handleDocumentClicked);

        return () => {
            document.removeEventListener('click', handleDocumentClicked);
        };
    }, []);
}
