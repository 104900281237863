import { BotActionType } from './AskHRBot.types';
import {
    shouldTriggerTokenExchangeAction,
    tokenExchangeAction,
} from './Actions/TokenExchangeAction';
import {
    botConnectionFulfilledAction,
    botIncomingActivityAdhocFlow,
    setBotOriginIndicator,
} from './Actions/AdhocFlowAction';
import { IAppContext } from './Models/IAppContext';
import { Next, Action, Dispatch, IDirectLine, NextAction } from 'botframework-webchat';
import {
    shouldTriggerUploadConversationHistoryAction,
    uploadConversationAction,
} from './Actions/UploadConversationHistoryAction';
import {
    conversationStartAction,
    keyBoardSubmitAction,
} from './Actions/ConversationDispatchAction';

export const actions: {
    [key: string]: (
        appContext: IAppContext,
        dispatch: Dispatch,
        next: Next,
        action: Action,
        directLine: IDirectLine
    ) => void | NextAction | Promise<void>;
} = {
    [BotActionType.CONNECT_FULFILLED]: (
        appContext: IAppContext,
        dispatch: Dispatch,
        next: Next,
        action: Action
    ): void => {
        botConnectionFulfilledAction();
        const botOrigin = setBotOriginIndicator();
        const conversationId = action?.payload?.directLine.conversationId;
        const dispatchPayload = {
            botHost: botOrigin,
            conversationId: conversationId,
        };
        conversationStartAction(dispatch, dispatchPayload); //Initate Dispatch to PVA

        return next(action);
    },
    [BotActionType.POST_ACTIVITY]: (
        appContext: IAppContext,
        dispatch: Dispatch,
        next: Next,
        action: Action
    ): void => {
        return next(action);
    },
    [BotActionType.SUBMIT_SEND_BOX]: (
        appContext: IAppContext,
        dispatch: Dispatch,
        next: Next,
        action: Action
    ): void => {
        keyBoardSubmitAction(dispatch);
        return next(action);
    },
    [BotActionType.INCOMING_ACTIVITY]: async (
        appContext: IAppContext,
        dispatch: Dispatch,
        next: Next,
        action: Action,
        directLine: IDirectLine
    ): Promise<void> => {
        const activity = action.payload.activity;

        if (shouldTriggerTokenExchangeAction(activity)) {
            tokenExchangeAction(appContext, activity, next, action, directLine);
        } else if (shouldTriggerUploadConversationHistoryAction(activity)) {
            await uploadConversationAction(appContext, dispatch, activity, next, action);
        } else botIncomingActivityAdhocFlow(next, action, dispatch, activity, appContext); //Initate Adhoc flow for rerouting to AdhocFlow Topic.
    },
};
