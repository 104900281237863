import * as React from 'react';
import { Link } from '@employee-experience/common/lib/Link';
import { Label } from 'office-ui-fabric-react';
import * as styled from './Footer.styled';
import * as WindowSize from '../../SupportPage/redux/hooks/useWindowDimensions';

export function Footer(): React.ReactElement {
    const year = new Date().getFullYear();
    const { width, height } = WindowSize.useWindowDimensions();

    return (
        <styled.FooterRoot data-testid="footer">
            <styled.Container>
                <styled.FooterMenus>
                    <styled.FooterMenuItem windowWidth={width} windowHeight={height}>
                        <Link
                            to="http://go.microsoft.com/fwlink/?LinkId=518021"
                            title="Microsoft data privacy notice"
                            target="_blank"
                            usageEvent={{
                                feature: 'AskHRSupport',
                                subFeature: 'Home',
                                subFeatureLevel2: 'Footer',
                            }}
                        >
                            Microsoft data privacy notice
                        </Link>
                    </styled.FooterMenuItem>
                    <styled.FooterMenuItem windowWidth={width} windowHeight={height}>
                        <Link
                            to="https://microsoft.sharepoint.com/sites/hrweb/sitePages/Legal-Notice.aspx"
                            title="Legal notice"
                            target="_blank"
                            usageEvent={{
                                feature: 'AskHRSupport',
                                subFeature: 'Home',
                                subFeatureLevel2: 'Footer',
                            }}
                        >
                            Legal notice
                        </Link>
                    </styled.FooterMenuItem>
                    <styled.FooterMenuItem
                        arie-aria-roledescription={`${year} Microsoft`}
                        windowWidth={width}
                        windowHeight={height}
                        tabIndex={0}
                        style={{ marginLeft: 'auto' }}
                    >
                        <Label style={{ fontWeight: 400 }}>&copy; {year} Microsoft</Label>
                    </styled.FooterMenuItem>
                </styled.FooterMenus>
            </styled.Container>
        </styled.FooterRoot>
    );
}
