import { css, FlattenSimpleInterpolation } from 'styled-components';
import { IResolutionProps } from '../../SupportPage/Models/IResolutionProps';

export const FooterRoot = styled.div`
    margin-top: 60px;
    background-color: #f8f8f8;
    padding-top: 36px;
    padding-bottom: 36px;
    border-top: 1px solid #c6c6c6;
`;

export const FooterMenus = styled.div`
    padding-left: 0;
    text-align: center;
    display: flex;
    justify-content: flex-start;
`;

export const FooterMenuItem = styled.div<IResolutionProps>`
    display: block;
    text-align: center;
    margin-bottom: 12px;
    display: inline-block;
    margin-left: 48px;
    margin-right: 25px;
    margin-bottom: 0;

    &&& a {
        color: #006cbe;
    }

    ${({ windowWidth }): number | FlattenSimpleInterpolation =>
        windowWidth < 700 &&
        css`
            margin-left: 0;
            margin-right: 15px;
        `}
`;

export const Container = styled.div`
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5vw;
    padding-right: 5vw;
`;
