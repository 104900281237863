export const Root = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
`;

export const Modal = styled.div`
    z-index: 1;
    background-color: white;
    width: 275px;
    box-shadow: rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px, rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px;
    border-radius: 2px;
    text-align: center;
    padding: 25px;
`;

export const ModalHeader = styled.h2`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 5px;
`;

export const ModalContent = styled.p`
    margin-bottom: 30px;
`;
