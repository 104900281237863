import { useState, useEffect, useCallback } from 'react';
import { debounce } from '@employee-experience/common/lib/Components/Dashboard/Dashboard.utils';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export function useWindowDimensions(): { width: number; height: number } {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    const handleResize = useCallback((): void => {
        setWindowDimensions(getWindowDimensions());
    }, []);

    const debouncedUpdateWidth = debounce(handleResize, 200);

    useEffect(() => {
        window.addEventListener('resize', debouncedUpdateWidth);
        return (): void => {
            window.removeEventListener('resize', debouncedUpdateWidth);
        };
    }, [debouncedUpdateWidth, handleResize]);

    return windowDimensions;
}
