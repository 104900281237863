import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import { Announced } from '@fluentui/react/lib/Announced';
import * as React from 'react';
import * as Styled from './AskHRFormResult.styled';

export function AskHRFormResult({ isSubmitted }: { isSubmitted: boolean }): React.ReactElement {
    return (
        <Styled.Root>
            {isSubmitted && (
                <MessageBar role='alert' messageBarType={MessageBarType.success}>
                    Your request has been submitted successfully!
                </MessageBar>
            )}
            {!isSubmitted && (
                <MessageBar role='alert' messageBarType={MessageBarType.info}>
                    Your request was cancelled.
                </MessageBar>
            )}
        </Styled.Root>
    );
}
