class AskHRFormContext {
    private askHRFormActivities: { [key: string]: boolean } = {};

    public hide(activityId: string, isSubmitted: boolean) {
        this.askHRFormActivities[activityId] = isSubmitted;
    }

    public shouldShow(activityId: string) {
        return !this.askHRFormActivities.hasOwnProperty(activityId);
    }
    public setTicketUploading(isloading: boolean) {
        this.askHRFormActivities['isUploadRequestLoading'] = isloading;
    }
    public isTicketUploadCompleted() {
        return this.askHRFormActivities.hasOwnProperty('isUploadRequestLoading') && this.askHRFormActivities['isUploadRequestLoading'];
    }
    public setUploadResponseStatus(isUploadSuccessful: boolean) {
        this.askHRFormActivities['isUploadSuccessful'] = isUploadSuccessful;
    }
    public isUploadSuccessful() {
        return this.askHRFormActivities.hasOwnProperty('isUploadSuccessful') && this.askHRFormActivities['isUploadSuccessful'];
    }

    public isSubmitted(activityId: string) {
        return this.askHRFormActivities[activityId];
    }
}

const context = new AskHRFormContext();
export { context as AskHRFormContext };
