import { useEffect } from 'react';
import { ConversationContext } from '../Context/ConversationContext';
import { ConversationEvent } from '../Context/ConversationContext/ConversationContext.types';
import { TextInputDisabler } from '../TextInputDisabler';

const id = 'useTextInputDisabler';

export function useTextInputDisabler(): void {
    useEffect(() => {
        ConversationContext.subscribe(id, ConversationEvent.Initialized, () =>
            TextInputDisabler.disable()
        );
        ConversationContext.subscribe(id, ConversationEvent.BotInitiatedConversation, () =>
            TextInputDisabler.enable()
        );
        ConversationContext.subscribe(id, ConversationEvent.BotRespondedToUser, () =>
            TextInputDisabler.enable()
        );
        ConversationContext.subscribe(id, ConversationEvent.UserRespondedToBot, () =>
            TextInputDisabler.disable()
        );
        ConversationContext.subscribe(id, ConversationEvent.BotRespondedToUserWithTktForm, () =>
        TextInputDisabler.disable('Please use the form to continue...')
        );
        ConversationContext.subscribe(id, ConversationEvent.UserOnlySelectOptions, () =>
            TextInputDisabler.disable('Please select an option...')
        );
        ConversationContext.subscribe(id, ConversationEvent.UnAuthorizedAccess, () =>
            TextInputDisabler.disable(' ')
        );

        return () => {
            ConversationContext.unsubscribe(id, ConversationEvent.Initialized);
            ConversationContext.unsubscribe(id, ConversationEvent.BotInitiatedConversation);
            ConversationContext.unsubscribe(id, ConversationEvent.BotRespondedToUser);
            ConversationContext.unsubscribe(id, ConversationEvent.UserRespondedToBot);
            ConversationContext.unsubscribe(id, ConversationEvent.UserOnlySelectOptions);
            ConversationContext.unsubscribe(id, ConversationEvent.UnAuthorizedAccess);
        };
    }, []);
}
