import { ITelemetryClient } from '@employee-experience/common/lib/Models/ITelemetryClient';
import { ComponentLoader } from '@employee-experience/core/lib/ComponentLoader';
import { GraphClient } from '@employee-experience/core/lib/GraphClient';
import { HttpClient } from '@employee-experience/core/lib/HttpClient';
import { MSALV2Client } from '@employee-experience/core/lib/MSALV2Client';
import { TelemetryClient } from '@employee-experience/core/lib/TelemetryClient';
import { ReducerRegistry } from '@employee-experience/shell/lib/ReducerRegistry';
import { Shell } from '@employee-experience/shell/lib/Shell';
import { StoreBuilder } from '@employee-experience/shell/lib/StoreBuilder';
import { withStore } from '@employee-experience/shell/lib/withStore';
import { IAskHRState } from './App.types';
import { searchSagas } from './SupportPage/redux/sagas/searchSagas';

const telemetryClient: ITelemetryClient = new TelemetryClient({
    instrumentationKey: __INSTRUMENTATION_KEY__,
    UTPConfig: {
        EnvironmentName: __IS_PRODUCTION__ ? 'Production' : 'Non-Production',
        ServiceOffering: 'Human Resources',
        ServiceLine: 'HR Experiences',
        Service: 'HR Digital Assistant',
        ComponentName: 'AskHR Virtual Assistant',
        ComponentId: '4b77ab60-72f1-481c-b645-176dca3bf0b0',
    },
    defaultProperties: {
        appName: __APP_NAME__,
    },
});
const authClient = new MSALV2Client(
    {
        auth: {
            clientId: __CLIENT_ID__,
            redirectUri: window.location.origin,
            authority: 'https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47',
        },
    },
    telemetryClient
);
const httpClient = new HttpClient(telemetryClient, authClient);
const graphClient = new GraphClient(httpClient);
const componentLoader = new ComponentLoader(telemetryClient, httpClient);

const reducerRegistry = new ReducerRegistry();
//const reducerRegistry = new ReducerRegistry();
const storeResult = new StoreBuilder<IAskHRState>(reducerRegistry, {})
    .configureSaga({ telemetryClient, authClient, httpClient, componentLoader, graphClient })
    //.addRootSagas([userProfileSagas])
    .addRootSagas([searchSagas])
    .configurePersistor({
        whitelist:
            process.env.NODE_ENV === 'development' ? ['userImpersonation'] : ['userImpersonation'],
        blacklist: ['dynamic'],
    })
    .build();

export const ShellWithStore = withStore(storeResult)(Shell);
