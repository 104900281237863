import { IBestBetSearchResponse } from '../../Models/IBestBetSearchResponse';
import { IComponentResponse } from '../../Models/IComponentResponse';
import { IHRwebSearchResponse } from '../../Models/IHRwebSearchResponse';
import {
    IFailedBestBetsSearchResultAction,
    IFailedHRwebSearchResultAction,
    IFailedManagerHubSearchResultAction,
    IReceiveBestBetsSearchResultAction,
    IReceiveHRwebSearchResultAction,
    IReceiveManagerHubSearchResultAction,
    IRequestBestBetsSearchResultAction,
    IRequestHRwebSearchResultAction,
    IRequestManagerHubSearchResultAction,
} from '../action-types/searchActionTypes';
import { SearchEnums } from '../enums/supportPageEnums';

export function requestHRwebSearchResult(searchTerm: string): IRequestHRwebSearchResultAction {
    return {
        type: SearchEnums.REQUEST_HRWEB_SEARCH_RESULT,
        searchTerm,
    };
}

export function receiveHRwebSearchResult(
    searchTerm: string,
    searchResponse: IHRwebSearchResponse
): IReceiveHRwebSearchResultAction {
    return {
        type: SearchEnums.RECEIVE_HRWEB_SEARCH_RESULT,
        searchTerm,
        searchResponse,
    };
}

export function failedHRwebSearchResult(searchTerm: string): IFailedHRwebSearchResultAction {
    return {
        type: SearchEnums.FAILED_HRWEB_SEARCH_RESULT,
        searchTerm,
    };
}

export function requestBestBetSearchResult(searchTerm: string): IRequestBestBetsSearchResultAction {
    return {
        type: SearchEnums.REQUEST_BESTBET_SEARCH_RESULT,
        searchTerm,
    };
}

export function receiveBestBetSearchResult(
    searchTerm: string,
    searchResponse: IBestBetSearchResponse
): IReceiveBestBetsSearchResultAction {
    return {
        type: SearchEnums.RECEIVE_BESTBET_SEARCH_RESULT,
        searchTerm,
        searchResponse,
    };
}

export function failedBestBetSearchResult(searchTerm: string): IFailedBestBetsSearchResultAction {
    return {
        type: SearchEnums.FAILED_BESTBET_SEARCH_RESULT,
        searchTerm,
    };
}

export function requestManagerHubSearchResult(
    searchTerm: string
): IRequestManagerHubSearchResultAction {
    return {
        type: SearchEnums.REQUEST_MANAGER_HUB_SEARCH_RESULT,
        searchTerm,
    };
}

export function receiveManagerHubSearchResult(
    searchTerm: string,
    searchResults: IComponentResponse[]
): IReceiveManagerHubSearchResultAction {
    return {
        type: SearchEnums.RECEIVE_MANAGER_HUB_SEARCH_RESULT,
        searchTerm,
        searchResults,
    };
}

export function failedManagerHubSearchResult(
    searchTerm: string,
    searchResults: IComponentResponse[]
): IFailedManagerHubSearchResultAction {
    return {
        type: SearchEnums.FAILED_MANAGER_HUB_SEARCH_RESULT,
        searchTerm,
        searchResults,
    };
}
