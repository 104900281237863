import { ITelemetryClient, CustomProperties, IAuthClient } from '@employee-experience/common/lib/Models';
import { IHttpClient, IHttpClientRequest, IHttpClientResult, IHttpHeader } from '@employee-experience/common';
import {
    TelemetryEventName,
    UsageCapabilityName,
    UsageEventName,
    UsageEventType,
    UsageSubCapabilityName,
} from '../Resources';
import { IFile } from '../Models/IFile';
import { IAskHrSupportApiConfig, ICaseDataUploadRequest } from './AskHrSupportApiService.types';
import { HttpClient } from '@employee-experience/core';
import { ICaseAttachmentsUploadResponse } from '../Models/ICaseAttachmentsUploadResponse';
import { ICaseDataFileUploadResponse } from '../Models/ICaseDataFileUploadResponse';

class AskHrSupportApiService {
    private config: IAskHrSupportApiConfig;

    private telemetryClient: ITelemetryClient | null = null;
    private httpClient: IHttpClient | null = null;

    public initialize(config: IAskHrSupportApiConfig, telemetryClient: ITelemetryClient, authClient: IAuthClient): void {

        this.config = config;

        this.telemetryClient = telemetryClient;
        if (!this.telemetryClient) throw new Error('TelemetryClient not found');

        if (!authClient) throw new Error('AuthClient not found');

        this.httpClient = new HttpClient(this.telemetryClient, authClient);

        try {

            this.telemetryClient.trackCustomEvent({
                name: TelemetryEventName.AskHrSupportApiServiceInitialized,
                properties: {
                    UsageEventType: UsageEventType.SystemAction,
                    UsageCapabilityName: UsageCapabilityName.AskHrSupportApi,
                    UsageSubCapabilityName: UsageSubCapabilityName.Initialization,
                    UsageEventName: UsageEventName.DependencyInitialized,
                },
            });
        } catch (e) {
            const heartMatrix: CustomProperties = {
                UsageEventType: UsageEventType.SystemAction,
                UsageCapabilityName: UsageCapabilityName.AskHrSupportApi,
                UsageSubCapabilityName: UsageSubCapabilityName.Initialization,
                UsageEventName: UsageEventName.DependencyInitializationFailed,
            };

            this.telemetryClient.trackCustomEvent({
                name: TelemetryEventName.AskHrSupportApiServiceNotInitialized,
                properties: heartMatrix,
            });
            this.telemetryClient.trackException(e, heartMatrix);
        }
    }

    public async uploadCaseAttachments(files: IFile[]): Promise<string[]> {

        try {

            const requestUri: string = `${this.config.apiBaseUri}api/ingestion/case-attachments`;

            const contentTypeHeader: IHttpHeader = {
                'Content-Type': 'multipart/form-data'
            };

            const formData: FormData = new FormData();

            files.forEach((newFile: IFile) => {

                const fileName: string = newFile.name;
                const formFileData: File = new File([newFile.buffer], fileName);

                formData.append(fileName, formFileData);
            });

            const httpRequest: IHttpClientRequest = {
                header: contentTypeHeader,
                resource: this.config.apiResourceId,
                data: formData
            };

            const response: IHttpClientResult<ICaseAttachmentsUploadResponse> = await this.httpClient.post(requestUri, httpRequest);

            this.telemetryClient.trackCustomEvent({
                name: TelemetryEventName.AskHrSupportApiOperationSucceeded,
                properties: {
                    UsageEventType: UsageEventType.SystemAction,
                    UsageCapabilityName: UsageCapabilityName.AskHrSupportApi,
                    UsageSubCapabilityName: UsageSubCapabilityName.UploadCaseAttachments,
                    UsageEventName: UsageEventName.DependencyRequestSucceeded,
                },
            });

            return response.data.fileNames;

        } catch (e) {

            const heartMatrix: CustomProperties = {
                UsageEventType: UsageEventType.SystemAction,
                UsageCapabilityName: UsageCapabilityName.AskHrSupportApi,
                UsageSubCapabilityName: UsageSubCapabilityName.UploadCaseAttachments,
                UsageEventName: UsageEventName.DependencyRequestFailed,
            };

            this.telemetryClient.trackCustomEvent({
                name: TelemetryEventName.AskHrSupportApiOperationFailed,
                properties: heartMatrix,
            });

            this.telemetryClient.trackException(e, heartMatrix);

            throw e;

        }
    }

    public async uploadCaseData(caseDataUploadRequest: ICaseDataUploadRequest): Promise<string> {

        try {

            const requestUri: string = `${this.config.apiBaseUri}api/ingestion/case-data`;

            const contentTypeHeader: IHttpHeader = {
                'Content-Type': 'multipart/form-data'
            };

            const formData: FormData = new FormData();

            formData.append("conversationId", caseDataUploadRequest.conversationId);

            const fileName = `${caseDataUploadRequest.conversationId}.json`;

            const file: File = new File([caseDataUploadRequest.blobContent], fileName);

            formData.append(fileName, file);

            const httpRequest: IHttpClientRequest = {
                header: contentTypeHeader,
                resource: this.config.apiResourceId,
                data: formData
            };

            const response: IHttpClientResult<ICaseDataFileUploadResponse> = await this.httpClient.post(requestUri, httpRequest);

            this.telemetryClient.trackCustomEvent({
                name: TelemetryEventName.AskHrSupportApiOperationSucceeded,
                properties: {
                    UsageEventType: UsageEventType.SystemAction,
                    UsageCapabilityName: UsageCapabilityName.AskHrSupportApi,
                    UsageSubCapabilityName: UsageSubCapabilityName.UploadCaseData,
                    UsageEventName: UsageEventName.DependencyRequestSucceeded,
                },
            });

            return response.data.fileName;

        } catch (e) {

            const heartMatrix: CustomProperties = {
                UsageEventType: UsageEventType.SystemAction,
                UsageCapabilityName: UsageCapabilityName.AskHrSupportApi,
                UsageSubCapabilityName: UsageSubCapabilityName.UploadCaseData,
                UsageEventName: UsageEventName.DependencyRequestFailed,
            };

            this.telemetryClient.trackCustomEvent({
                name: TelemetryEventName.AskHrSupportApiOperationFailed,
                properties: heartMatrix,
            });

            this.telemetryClient.trackException(e, heartMatrix);

            throw e;

        }
    }
}

const askHrSupportApiService = new AskHrSupportApiService();
export { askHrSupportApiService as AskHrSupportApiService };
