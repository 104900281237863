import { INavProps } from '@employee-experience/shell';
import { INav, mergeStyleSets } from '@fluentui/react';

export const AppStyles = mergeStyleSets({
    navStyle: {
        selectors: {
            '@media screen and (max-width: 1024px)': {
                margin: '45px 0 0 0',
            },
        },
    },
    bannerStyle: {
        selectors: {
            '@media screen and (max-width: 1024px)': {
                margin: '0px 0 0 0',
            },
            '@media screen and (min-width: 1024px)': {
                margin: '48px 0 0 0',
            },
        },
    },
});
