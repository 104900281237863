import * as React from 'react';
import * as Styled from './TimeoutOverlay.styled';
import { PrimaryButton } from '@employee-experience/common/lib/PrimaryButton';
import {
    TelemetryEventName,
    UsageCapabilityName,
    UsageEventName,
    UsageEventType,
    UsageSubCapabilityName,
} from '../Resources';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';

export function TimeoutOverlay(): React.ReactElement {
    const { telemetryClient } = React.useContext(ComponentContext);

    React.useEffect(() => {
        telemetryClient.trackCustomEvent({
            name: TelemetryEventName.SessionTimeOutModalShown,
            properties: {
                UsageEventName: UsageEventName.Session,
                UsageEventType: UsageEventType.SystemAction,
                UsageCapabilityName: UsageCapabilityName.Platform,
                UsageSubCapabilityName: UsageSubCapabilityName.SessionTimeoutModal,
            },
        });
    }, []);

    const handleRefreshButtonClicked = (): void => {
        telemetryClient.trackCustomEvent({
            name: TelemetryEventName.SessionRefreshRequested,
            properties: {
                UsageEventName: UsageEventName.Session,
                UsageEventType: UsageEventType.UserAction,
                UsageCapabilityName: UsageCapabilityName.Platform,
                UsageSubCapabilityName: UsageSubCapabilityName.SessionRefresh,
            },
        });

        window.location.reload();
    };

    return (
        <Styled.Root>
            <Styled.Overlay />
            <Styled.Modal>
                <Styled.ModalHeader>Session expired</Styled.ModalHeader>
                <Styled.ModalContent>
                    Your session has expired. <br />
                    Please refresh to continue.
                </Styled.ModalContent>
                <PrimaryButton
                    text="Refresh"
                    title="Reload the page"
                    onClick={handleRefreshButtonClicked}
                    usageEvent={{
                        feature: UsageCapabilityName.WebChat,
                        subFeature: UsageSubCapabilityName.SessionRefresh,
                    }}
                />
            </Styled.Modal>
        </Styled.Root>
    );
}
