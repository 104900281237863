import { IHttpClient, IHttpClientResult } from '@employee-experience/common';
import {
    getAPICallFeature,
    getFeature,
} from '@employee-experience/common/lib/Helpers/UsageTelemetryHelper';
import { SagaIterator } from 'redux-saga';
import { all, getContext, call, put, takeLatest } from 'redux-saga/effects';
import { IBestBetSearchResponse } from '../../Models/IBestBetSearchResponse';
import { IHRwebSearchResponse } from '../../Models/IHRwebSearchResponse';
import {
    IRequestBestBetsSearchResultAction,
    IRequestHRwebSearchResultAction,
} from '../action-types/searchActionTypes';
import {
    failedBestBetSearchResult,
    failedHRwebSearchResult,
    receiveBestBetSearchResult,
    receiveHRwebSearchResult,
} from '../actions/searchActions';
import { SearchEnums } from '../enums/supportPageEnums';

function* fetchHRwebSearchResults(action: IRequestHRwebSearchResultAction): SagaIterator {
    const { searchTerm } = action;
    const maxResults = __HRWEB_MAX_SEARCH_RESULTS__;

    const httpClient: IHttpClient = yield getContext('httpClient');
    const searchUrl = __HRWEB_CONTENT_API_BASE_URL__ + '/api/v1/HRWebContent/SearchContent';
    let response: IHttpClientResult<IHRwebSearchResponse> | null = null;

    try {
        response = yield call(
            [httpClient, httpClient.request],
            {
                url: searchUrl,
                resource: __HRWEB_CONTENT_API_RESOURCE_URI__,
                method: 'post',
                data: {
                    queryText: searchTerm,
                    RowLimit: maxResults,
                },
            },
            {},
            getAPICallFeature(getFeature('SearchResultsHRWeb', 'Search'), 'AskHRSupport')
        );

        yield put(receiveHRwebSearchResult(searchTerm, response.data));
    } catch (ex) {
        yield put(failedHRwebSearchResult(searchTerm));
    }
}

function* fetchBestBetSearchResults(action: IRequestBestBetsSearchResultAction): SagaIterator {
    const { searchTerm } = action;

    const httpClient: IHttpClient = yield getContext('httpClient');
    const searchUrl = __HRWEB_CONTENT_API_BASE_URL__ + '/api/v1/HRWebContent/SearchBestBets';
    let searchResults: IHttpClientResult<IBestBetSearchResponse> | null = null;
    try {
        searchResults = yield call(
            [httpClient, httpClient.request],
            {
                url: searchUrl,
                resource: __HRWEB_CONTENT_API_RESOURCE_URI__,
                method: 'post',
                data: {
                    queryText: searchTerm,
                },
            },
            {},
            getAPICallFeature(getFeature('SearchResultsBestBet', 'Search'), 'AskHRSupport')
        );

        yield put(receiveBestBetSearchResult(searchTerm, searchResults.data));
    } catch (ex) {
        yield put(failedBestBetSearchResult(searchTerm));
    }
}

export function* searchSagas(): SagaIterator {
    yield all([takeLatest(SearchEnums.REQUEST_HRWEB_SEARCH_RESULT, fetchHRwebSearchResults)]);
    yield all([takeLatest(SearchEnums.REQUEST_BESTBET_SEARCH_RESULT, fetchBestBetSearchResults)]);
}
