import { Dispatch } from 'botframework-webchat';

export function conversationStartAction(dispatch: Dispatch, value: any): void {
    dispatch({
        type: 'WEB_CHAT/SEND_EVENT',
        payload: {
            name: 'startConversation',
            type: 'event',
            value: value,
        },
    });
}

export function setPVAGlobalVariable(dispatch: Dispatch, value: any): void {
    dispatch({
        type: 'WEB_CHAT/SEND_EVENT',
        payload: {
            name: 'pvaSetContext',
            type: 'event',
            value: value,
        },
    });
}

export function publishValidUserResponse(dispatch: Dispatch): void {
    dispatch({
        type: 'PUBLISH_VALID_USERRESPONSE',
        payload: {
            text: true,
        },
    });
}

export function keyBoardSubmitAction(dispatch: Dispatch): void {
    dispatch({
        type: 'PUBLISH_KEYBOARD_SUBMIT',
        payload: {
            text: true,
        },
    });
}

export function sendHiddenMessageToBot(dispatch: Dispatch, text: string): void {
    dispatch({
        type: 'WEB_CHAT/SEND_MESSAGE_BACK',
        payload: {
            text: text,
        },
    });
}
