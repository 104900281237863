import { useEffect, useState, useCallback } from 'react';
import { ConversationContext } from '../Context/ConversationContext';
import { ConversationEvent } from '../Context/ConversationContext/ConversationContext.types';

const id = 'useSessionTimeout';

export function useSessionTimeout(): boolean {
    const [isTimedOut, setTimeout] = useState<boolean>(false);

    const handleTimeout = useCallback(() => {
        setTimeout(true);
    }, []);

    useEffect(() => {
        ConversationContext.subscribe(id, ConversationEvent.SessionTimedOut, handleTimeout);

        return () => {
            ConversationContext.unsubscribe(id, ConversationEvent.SessionTimedOut);
        };
    }, []);

    return isTimedOut;
}
