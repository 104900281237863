import * as React from 'react';
import { INavLinkGroup, Nav as EENav } from '@employee-experience/shell/lib/Nav';

export interface INavItem {
    key: string;
    name: string;
    href: string;
    icon: string;
    bundleLocation: string;
    componentName: string;
}

export default function Nav(): React.ReactElement {
    const homeItem: INavItem = {
        key: 'Support',
        name: 'HRSupport',
        href: '/support',
        icon: 'LaptopSecure',
        bundleLocation: 'Support',
        componentName: 'Support',
    };
    const hrWeb: INavItem = {
        key: 'HRweb',
        name: 'HRweb',
        href: 'https://aka.ms/hrweb',
        icon: 'HRWeb',
        bundleLocation: '',
        componentName: 'HRweb',
    };
    const navLink: INavLinkGroup = {
        key: 'main',
        links: [homeItem, hrWeb],
    };

    const navGroup: INavLinkGroup[] = [navLink];

    return <EENav groups={navGroup} isNavCollapsed={true} />;
}
