export enum ActivityType {
    'MESSAGE' = 'message',
    'Event' = 'event',
    'AskHRForm' = 'AskHRForm',
}

export enum BotActionType {
    'CONNECT_FULFILLED' = 'DIRECT_LINE/CONNECT_FULFILLED',
    'INCOMING_ACTIVITY' = 'DIRECT_LINE/INCOMING_ACTIVITY',
    'SEND_MESSAGE' = 'WEB_CHAT/SEND_MESSAGE',
    'SEND_TYPING' = 'WEB_CHAT/SEND_TYPING',
    'POST_ACTIVITY' = 'DIRECT_LINE/POST_ACTIVITY',
    'SUBMIT_SEND_BOX' = 'WEB_CHAT/SUBMIT_SEND_BOX',
    'POST_BACK' = 'WEB_CHAT/SEND_POST_BACK',
}

export const AskHRTicketFormTrigger = '{AskHRTicketForm}';

export enum ActionTypes {
    PUBLISH_CHATHISTORY = 'PUBLISH_CHATHISTORY',
}

export interface IBotActivityProps {
    DefaultComponent: React.ComponentType;
}

export enum BotResponseType {
    BestBet = 'BestBet',
    Search = 'Search',
    Unknown = 'Unknown',
}

export enum BotContentType {
    BestBet = 'Best Bet',
    BotItem = 'BOT Item',
}

export interface IBestBetResponse {
    TargetCompanyCode: string;
    ImpersonatedRole: string;
    ImpersonatedAlias: string;
    CorrelationId: string;
    BestBetResults: IBestBetResult[];
}

export interface IBestBetResult {
    BOTResponse: string;
    ContentType: BotContentType;
    DocumentDescription: string;
    Title: string;
    URL: string;
}

export interface ISearchResponse {
    TargetCompanyCode: string;
    ImpersonatedRole: string;
    ImpersonatedAlias: string;
    CorrelationId: string;
    SearchResults: ISearchResult[];
}

export interface ISearchResult {
    Description: '';
    HitHighlightedSummary: string;
    Path: string;
    Title: string;
}
