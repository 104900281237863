import { Icon } from 'office-ui-fabric-react/lib/Icon';

export const Root = styled.button`
    background: none;
    border: none;
    margin-top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
`;

export const DeleteIcon = styled(Icon).attrs({
    iconName: 'delete',
})`
    width: 16px;
    height: 16px;
    font-size: 16px;
    margin-right: 6px;
    color: rgb(0, 120, 215);
`;

export const Size = styled.p`
    font-size: 14px;
    color: #646464;
    margin-left: 6px;
`;
