import * as sanitizer from 'sanitize-html';
import { IOptions } from 'sanitize-html';
import { normalizeUrl } from './normalizeUrl';

const sanitizerConfig: IOptions = {
    allowedClasses: {
        'div': ['description', 'best-bet-title', 'askhr-bot-item'],
        'p': ['best-bet-title'],
        'ul': ['main-list'],
        'li': ['list-item'],
    },
    allowedTags: [
        'b',
        'i',
        'u',
        'em',
        'ul',
        'ol',
        'li',
        'strong',
        'p',
        'div',
        'br',
        'a',
        'table',
        'tbody',
        'td',
        'tfoot',
        'th',
        'thead',
        'tr',
        'hr',
        'head',
        'style'
    ],
    allowedAttributes: {
        a: ['href', 'target', 'style', 'href', 'rel', 'title', 'data-interception', 'class'],
        ul: ['class'],
        li: ['class'],
        p: ['aria-label', 'class']
    },
    selfClosing: ['br'],
    transformTags: {
        a: sanitizer.simpleTransform('a', {
            target: '_blank',
            'data-interception': 'off',
        }),
    },
};

function normalizeLinks(htmlString: string): string {
    let fixed = htmlString.replace(/href="(.*?)"/gi, function (m, $1) {
        return `href="${normalizeUrl($1)}"`;
    });
    fixed = fixed.replace(/href='(.*?)'/gi, function (m, $1) {
        return `href="${normalizeUrl($1)}"`;
    });

    return fixed;
}

export function getSanitizedHtml(dirtyHtmlString: string): string {
    if (!dirtyHtmlString) return dirtyHtmlString;

    let cleanString = sanitizer(dirtyHtmlString, sanitizerConfig);
    cleanString = normalizeLinks(cleanString);

    return cleanString;
}
