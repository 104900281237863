import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProvider } from '@employee-experience/common/lib/RouteComponentProvider';
import { AskHRBot } from './AskHRBot/AskHRBot';

export function Routes(): React.ReactElement {
    return (
        <Switch>
            <Route path="/" component={AskHRBot} exact={true} />
            <RouteComponentProvider
                path="/support"
                config={{
                    script: `/bundles/askhrsupportpage.js`,
                    name: 'AskHRSupportPage',
                }}
            />
            <RouteComponentProvider
                path="/hrhelpform"
                config={{
                    script: `/bundles/helpform.js`,
                    name: 'HelpForm',
                }}
            />
            <RouteComponentProvider
                path="/search"
                config={{
                    script: `/bundles/search.js`,
                    name: 'Search',
                }}
            />
        </Switch>
    );
}
