import * as React from 'react';
import { AskHRFormContext } from '../../Context/AskHRFormContext/AskHRFormContext';
import { AskHRForm } from './AskHRForm';
import { Card, NextCard } from 'botframework-webchat/lib';
import { IAppContext } from '../../Models/IAppContext';
import { AskHRFormResult } from './AskHRFormResult';

export function AskHRFormActivity(
    appContext: IAppContext,
    card: Card
): React.ComponentType<{ nextCard: NextCard }> {
    return ({ nextCard }): React.ReactElement => {
        const activityId = card.activity.id;

        return nextCard(() =>
            AskHRFormContext.shouldShow(activityId) ? (
                <AskHRForm activityId={activityId} appContext={appContext} />
            ) : (
                !AskHRFormContext.isTicketUploadCompleted() && (<AskHRFormResult isSubmitted={AskHRFormContext.isUploadSuccessful()} />)
            )
        );
    };
}
