import { Font } from '../../../Resources';
import { PrimaryButton } from '@employee-experience/common/lib/PrimaryButton';

export const Root = styled.div.attrs({ className: 'custom-response' })`
    padding: 10px;
    font-family: ${Font.Default};
    line-height: 1.3;
    width: 100%;
    position: relative;
    max-width: 600px;
`;

export const Disclaimer = styled.p`
    font-size: 10px;
    color: #646464;
    margin-bottom: 12px;
`;

export const SubmitButton = styled(PrimaryButton)`
    margin-right: 12px;
`;

export const Link = styled.a`
    font-weight: 600;
`;
