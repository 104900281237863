import { nullRender } from 'office-ui-fabric-react';
import { IAdhocFlowContext, IAdhocFlowContextUpdateModel } from './AdhocFlowContext.types';

class AdhocFlowContext {
    private adhocFlowContext: IAdhocFlowContext = {
        isValidUserText: false,
        isGuidedConversationStarted: 0,
        isAdhocFlowStarted: false,
        isAdhocFlowFailed: false,
        isAdhocFlowClosed: false,
        userQuestionForSearch: null,
        botOrigin: null,
    };

    public constructor() {}

    public getAdhocFlowContext(): IAdhocFlowContext {
        return this.adhocFlowContext;
    }

    public updateAdhocFlowContext(update: IAdhocFlowContextUpdateModel): void {
        const prev = this.adhocFlowContext;

        this.adhocFlowContext = {
            ...this.adhocFlowContext,
            ...update,
        };
    }
}

const context = new AdhocFlowContext();
export { context as AdhocFlowContext };
