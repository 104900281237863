import { Buffer } from 'buffer';
import { IFile } from '../../../Models/IFile';

export function readFile(file: File): Promise<IFile> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (): void => {
            const base64 = reader.result?.toString();
            if (!base64) throw new Error('File not loaded properly');

            const split = file.name.split('.');
            const extension = split[split.length - 1];
            const buffer = new Buffer(base64.split(',')[1], 'base64');

            const result: IFile = {
                name: file.name,
                extension,
                buffer,
                bufferLength: Buffer.byteLength(buffer),
            };

            resolve(result);
        };

        reader.onerror = reject;
    });
}
