import { createGlobalStyle } from 'styled-components';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Color, Font } from './Resources';

const defaultChatHeaderHeight = 48;
const defaultFooterHeight = 30;

export const Root = styled.div`
    background-color: ${Color.White};
    height: 100%;
`;

export const Header = styled.h1`
    background-color: ${Color.HeaderBackground};
    height: ${defaultChatHeaderHeight}px;
    max-height: ${defaultChatHeaderHeight}px;
    line-height: ${defaultChatHeaderHeight}px;
    color: ${Color.White};
    font-size: 16px;
    font-weight: 600;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0;
`;

export const BetaFlag = styled.span`
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 1px;
    padding-bottom: 2px;
    border-radius: 3px;
    margin-left: 5px;
    background-color: aliceblue;
    color: black;
    background-color: rgb(255, 235, 150);
`;

export const BotRoot = styled.main`
    width: 100%;
    height: calc(100% - ${defaultChatHeaderHeight + defaultFooterHeight}px);
    background-color: ${Color.BotBackground};
    position: relative;
`;

export const Footer = styled.footer`
    height: ${defaultFooterHeight}px;
    background-color: ${Color.FooterBackground};
    padding-left: 10px;
    padding-right: 10px;
    text-align: right;
`;

export const FooterLink = styled.a`
    font-weight: 600;
    line-height: ${defaultFooterHeight}px;
    &:hover {
        text-decoration: underline;
    }
`;

export const SpinnerRoot = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 100px;
    width: 100%;
    text-align: center;
`;

export const InitialSpinner = styled(Spinner).attrs({
    size: SpinnerSize.large,
})`
    display: inline-block;
`;

export const BotStyleOverrides = createGlobalStyle`
  #askhrbot {
    font-size: 14px;
    line-height: 1.3;
    position:relative;
    top: 48px;
  }

  #askhrbot .webchat__bubble {
    max-width: 80%;
      
    @media (min-width: 600px) {
      max-width: 60%;
    }
  }

  #askhrbot button {
    min-width: unset;
    margin-left: 0;
  }

  #askhrbot .webchat__suggested-action__button {
    color: ${Color.Black} !important;
  }

  #askhrbot .css-1abghdv {
    padding-bottom: 0;
  }

  #askhrbot .css-eycyw2.css-1882x3w.css-1l9a57c {
    max-height: 60%;
  }

  #askhrbot .css-eycyw2.css-1882x3w.css-1l9a57c > div:first-of-type {
    height: calc(100% - 40px);
    overflow: auto;
  }

  #askhrbot .css-c45sou {
    display: none;
  }

  // Disable original spinner
  #askhrbot .webchat__connectivityStatus {
    display: none;
  }

  // SUGGESTION BUTTON STYLE START
  #askhrbot .webchat__suggested-actions__carousel {
    padding-bottom: 0;
  }

  #askhrbot .webchat__suggested-actions ul {
    background-color: white;
    padding: 8px;
    flex-wrap: wrap;
  }

  #askhrbot .webchat__suggested-actions ul > li {
    padding-left: 0;
  }

  #askhrbot .webchat__suggested-actions ul > li button {
    color: ${Color.Black} !important;
    font-weight: 600;
    margin: 0;
    padding-left: 16px;
    padding-right: 16px;
    min-width: unset;
    flex-wrap: wrap;
  }

  // SUGGESTION BUTTON STYLE END

  #askhrbot .from-user.bubble p {
    margin: 0;
    line-height: 1.3;
  }

  #askhrbot .askhr-bot-item a {
    padding: 0;
    margin: 0 0 16px;
    font-weight: 600;
    cursor: pointer;
    color: #0077D7;
    text-decoration: underline;
  }

  #askhrbot .askhr-bot-item ul {
    padding-left: 40px;
    list-style: disc;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  // Text input area
  #askhrbot .main > form {
    padding: 0;
  }

  #askhrbot input[data-id="webchat-sendbox-input"] {
    padding: 10px;
  }

  #askhrbot input[data-id="webchat-sendbox-input"][disabled] {
    background-color: rgb(240, 240, 240);
  }

  // Send button
  #askhrbot .css-115fwte[disabled] {
    background-color: rgb(240, 240, 240);
  }
`;

export const BotStyles = {
    avatarSize: 40,
    backgroundColor: Color.BotBackground,
    primaryFont: Font.Default,
    botAvatarImage:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAATUSURBVFhHrVdbTFxVFF13HsDglIe2RSdUaNSaaGurUu0jLTZ+6If1w7Q0EdNYfzRRG3/9IIzaqL9N9UNjSLQvC41+SIwxMfRtJKRtFJoUK+LUUm2ZUhg6MAPDde1z7sCduXceCIsszjn7zj17n7333eccwzRNFAPjvZNBNqvJ7eRmchV5DymIkv3kWfI7mOg1w43j8qAQChpAxVVsmsldpCj2kPkwQ4ohx2jIYRpyW0lzIK8BVL6HzbvkQ0owf/xOfmy2NrbpoROuBlirPkC+ogQLxyHybRri8IbDACoPsTlOblSCxcPP5A4aMaSHGhkGUHmAzQ/kViUohMlp3Zb5dFsYp8jnacSEHjoT6guyOOUzJl5cW4PnVi9XfSZcMZC5RccsZj3A1e9m86UaFAIVejwGUq2NamiET6gWhqHbQjCxm1/HQekqD1hJ94H0XTHNL+vOFJmc5Uzccr9A+rZniPO38k4uGNhnhJVO7QEasJf9/SJwYCqFhroqvLk+hGCJFynlML7Dv6ZHl6mfHOu7qWQys5dOGE1M45PuIVy8Ogb4c5QNE+/QC/sNhE9I4nWRT6sHdlD5g8uD6Hp1LWorSi1hcfhrdBJb2y4iEmW+uRvRTT4jT9aR60XiQHIGT4aC81YuqKssw1O1FWoROdBArhMDXiDd/UR32svEOCd7vbMfu9r7MBRLWFKudmwSLx3txVvfX2H452LPPM0H0bld/m1Swxywf13fXBrG511RtP8yhoO//mtJgc96ruPbnhg+/ekmOvtlX9LIqnFu2CgGPKz7hbFpBV1qYcv9KokVnl1ZbfWADeL24rFKklB8WaLHWeDntLMhhPadj1gCEaWYGiaqSjOr3wirYpnPgwCZRlPHJXT0sPKW+y2JA0n32NvgySou5X6vQ7mgmuXYrlyQ/a4LDHljLmguSOgP/38hNmUrVu4YFgMu674LSnw4PXALpyOjlqB4/PjHLZz7k7tv/o3qsuTAh+zIoWMOsugkrZdWSupdfjTWVyNY6mVm5/eIuP028+HMIJVLSZawSCS4GNVm4iMxQPb9M6QOIBX4vB48cd8Sll6PKr0JGjEtO9484GcRKKFyLw0aY2m+8M84UikuZi4vpGBsMdDKUmzYSjFXvmZFJc699rhKqgWkgILsDXdYnDa3XUDv3wyleEKjmx7e5uGGIIeDI1pGKI971cbjlVVwhoVQ5qhg6MolFKYtBiaOUnfccju+4v+I6tP9UmYljouF6MQUrsW4Tc/VZtGlzh7KAOvo3CJ9SZrIjTgjM6iGi4F9pyK4Fo3rhBSYaKHOEelmnwkPs3lZFXEG/40NtXh/Wz2W5a5keTFKL7Z0DeLA2atUztXrBDzCM6HcMxRyH0pFnkihribIg8dSND9Wg5VVARXPfIglUxgYmcRxHlK+7ruBK0MxQN7Ryh2H0gwDBDQi81gu+7n8hO6rvzuAhtASPFAdwL1BPyqtIhOjoRLjgZEJnL8ew8Aw55fPxyB9olz9rPCxPA3rjOi8mMikUpjke5b30q+KAlkhE1i5WtpMyMVkL5WruNvhakAaPDju4eQLu5qZvJqF53k1s4NGyGbfTEOa2BZ/OTXRwfZQOttzoaABaTAscj1fQ8oRLn09X0oKhsn09byTyn+j4iKu58B/pGHhLQAjt8EAAAAASUVORK5CYII=',
    // Suggestion buttons
    suggestedActionBackground: Color.White,
    suggestedActionBorderRadius: 2,
    suggestedActionBorderColor: 'rgb(138, 136, 134)',
    suggestedActionBorderWidth: 1,
    timestampColor: 'rgb(23, 23, 23)',
    groupTimestamp: 0,
};
