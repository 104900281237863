import { DefaultButton } from '@employee-experience/common/lib/DefaultButton';
import { InputGroup } from '@employee-experience/common/lib/InputGroup';
import { TextField } from '@employee-experience/common/lib/TextField';
import { ITextField } from 'office-ui-fabric-react/lib/components/TextField';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';
import * as React from 'react';
import { sendHiddenMessageToBot } from '../../../Actions/ConversationDispatchAction';
import { AdhocFlowContext } from '../../../AdhocFlowContext/AdhocFlowContext';
import { AskHRFormContext } from '../../../Context/AskHRFormContext/AskHRFormContext';
import { ConversationContext } from '../../../Context/ConversationContext';
import { IAppContext } from '../../../Models/IAppContext';
import { IFile } from '../../../Models/IFile';
import {
    TelemetryEventName,
    UsageCapabilityName,
    UsageEventName,
    UsageEventType,
    UsageSubCapabilityName,
} from '../../../Resources';
import { Spinner } from '../../../Spinner/Spinner';
import { TextInputDisabler } from '../../../TextInputDisabler';
import { AskHRFormAttachments } from '../AskHRFormAttachments';
import * as Styled from './AskHRForm.styled';
import { IAskHRRequest } from './AskHRForm.types';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { ChoiceGroup, IChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import { CheckboxDescription } from '../../../../SupportPage/CreateCase/CreateCase.styled';
import { EventType, UserEvent } from '@employee-experience/common';
import { IStackProps, Stack } from '@fluentui/react';
import { v4 as uuidv4 } from 'uuid';
import { TicketUploadUID } from '../../../../Shared/Constants';
import { AskHrSupportApiService } from '../../../APIs';

const maxAttachmentSize = 8388608;
const subjectCharacterLimit = 300;

// list of allowed extensions for attachment upload validation
// list for actual attachment form is in `src\AskHRBot\Activities\AskHRFormActivity\AskHRFormAttachments\AskHRFormAttachments.tsx`
// any updates to this list should be replicated there & vice versa
// ideally this should be moved into a constants class somewhere where it can be used in both
// no leading `.` characters in the extension since the `IFile.extension` string returns the extension without the leading `.`
const allowedExtensions: string[] = ["png", "jpg", "jpeg", "txt", "docx", "doc", "xls", "xlsx", "ppt", "pptx", "pdf", "msg"];

export const optionValidatorText: Partial<IStackProps> = {
    root: {
        style: {
            fontSize: '13px',
            color: '#a4262c',
            fontWeight: '400',
            margin: '0px',
            paddingTop: '5px',
        }
    }
}

export function AskHRForm({
    activityId,
    appContext,
}: {
    activityId: string;
    appContext: IAppContext;
}): React.ReactElement {
    const { telemetryClient } = appContext;
    const ref = React.useRef<ITextField>();
    const choiceRef = React.useRef<IChoiceGroup>();
    const refDescription = React.useRef<ITextField>();

    const [validationErrors, setValidationErrors] = React.useState<{ [key: string]: boolean }>({});
    const [serverError, setServerError] = React.useState<string>('');
    const [isSubmitting, setSubmitting] = React.useState<boolean>();
    const getAdhocFlowContext = AdhocFlowContext.getAdhocFlowContext();
    const descriptionTemplate =
        getAdhocFlowContext.botOrigin.toLowerCase() === 'learn'
            ? `What is the purpose of this ticket - issue resolution, question, or feedback?
\n\nIs this about a specific course? If yes, please provide the course ID and/or title if you know them: 
\nProvide a description of your request, make sure to provide any links if needed: `
            : getAdhocFlowContext.botOrigin.toLowerCase() === 'cv19'
                ? `Please answer the following questions:
\nFirst day of symptoms: 
\nDate of your positive test: 
\nLast day onsite or with other employees: 
\nBuilding location: 
\nProvide a list of employees you were in close contact with (within 6 ft/2m, greater than 15 minutes): 
\nAdditional information, event details (if applicable), or further questions: \n`
                : '';

    const disableFlag =
        (getAdhocFlowContext.botOrigin.toLowerCase() === 'learn' || getAdhocFlowContext.botOrigin.toLowerCase() === 'cv19') ? false : true;

    const [incidentDescription, setIncidentDescription] =
        React.useState<string>(descriptionTemplate);
    const dispatch = ConversationContext.getDispatch();
    const [helperText, setHelperText] = React.useState('');
    const [optionError, setError] = React.useState({ isVald: false });

    React.useEffect(() => {
        TextInputDisabler.disable('Please use the form to continue...');

        if (!ref.current) return;
        ref.current.focus();

        telemetryClient.trackCustomEvent({
            name: TelemetryEventName.AskHRTicketFormLoad,
            properties: {
                UsageEventType: UsageEventType.SystemAction,
                UsageCapabilityName: UsageCapabilityName.AskHRBot,
                UsageSubCapabilityName: UsageSubCapabilityName.AskHRTicket,
                UsageEventName: UsageEventName.PageLoad,
            },
        });
    }, []);

    const [request, setRequest] = React.useState<IAskHRRequest>({
        subject: '',
        description: '',
        attachments: [],
        topic: '',
        highPriority: false,
        uploadUID: null,
    });

    const handleChanged = (name: string, value: string | IFile[]): void => {
        if (name === 'subject' && value.length > subjectCharacterLimit) return;

        if (name === 'description') {
            setIncidentDescription(value.toString());
        }

        setRequest((p) => ({
            ...p,
            [name]: value,
        }));

        setValidationErrors((p) => {
            const clone = { ...p };
            delete clone[name];

            return clone;
        });

        if (ref.current && name != 'subject' && name != 'description') ref.current.focus();
    };

    const isValidRequest = () => {
        const errors: { [key: string]: boolean } = {};
        if (request.subject.trim().length === 0) errors.subject = true;
        if (request.description.trim().length === 0) errors.description = true;

        let totalSize = 0;
        for (const file of request.attachments) {
            totalSize += file.bufferLength;

            // can't upload empty files
            // just set the error and move on
            if (file.bufferLength == 0) {
                errors.attachments = true;
                break;
            }

            // shouldn't upload files with unsupported extensions
            // those files will cause the API call to fail anyway with a `400 Bad Request` result
            // set the error and move on
            if (!allowedExtensions.includes(file.extension)) {
                errors.attachments = true;
                break;
            }
        }

        // can't upload more than the max attachment size
        // set the error
        if (totalSize >= maxAttachmentSize) {
            errors.attachments = true;
        }

        setValidationErrors(errors);
        let _isValid = true;
        if (disableFlag) {
            _isValid = isValidOptionSelected();
            if (!_isValid) choiceRef.current.focus();
        }
        else if (errors.subject) ref.current.focus();
        else if (errors.description) refDescription.current.focus();
        return (Object.keys(errors).length === 0 && _isValid);
    };

    const isValidOptionSelected = (): boolean => {
        if (request.topic === null || request.topic === '') {
            setHelperText('Topic is required.')
            setError({ ...optionError, isVald: true });
            return false;
        } else {
            setHelperText('');
            setError({ ...optionError, isVald: false });
            return true;
        }
    }

    const handleSubmitted = async () => {
        if (!isValidRequest()) return;
        setSubmitting(true);
        Spinner.show();
        const uuid = uuidv4();
        try {
            let attachments: string[] = [];
            if (request && request.attachments && Array.isArray(request.attachments) && request.attachments.length > 0) {
                 attachments = await AskHrSupportApiService.uploadCaseAttachments(request.attachments);
            }

            const _appendUIDRequest = request;
            _appendUIDRequest.uploadUID = uuid;
            setRequest(_appendUIDRequest);
            sessionStorage.setItem(TicketUploadUID, uuid);

            telemetryClient.trackCustomEvent({
                name: TelemetryEventName.AskHRTicketSubmitClicked,
                properties: {
                    UsageEventType: UsageEventType.UserAction,
                    UsageCapabilityName: UsageCapabilityName.AskHRBot,
                    UsageSubCapabilityName: UsageSubCapabilityName.AskHRTicket,
                    UsageEventName: UsageEventName.WebChatMessage,
                    requestUploadUID: request.uploadUID,
                    localTktUploadUID: uuid,
                },
            });
            if (request.topic === 'other') {
                const _request = request;
                _request.topic = '';
                setRequest(_request);
            }
            AskHRFormContext.setTicketUploading(true);
            sendHiddenMessageToBot(
                dispatch,
                JSON.stringify({
                    ...request,
                    attachments,
                })
            );

            setTimeout(() => {
                AskHRFormContext.hide(activityId, true);
            });

            telemetryClient.trackCustomEvent({
                name: TelemetryEventName.AskHRTicketSubmitted,
                properties: {
                    UsageEventType: UsageEventType.UserAction,
                    UsageCapabilityName: UsageCapabilityName.AskHRBot,
                    UsageSubCapabilityName: UsageSubCapabilityName.AskHRTicket,
                    UsageEventName: UsageEventName.WebChatMessage,
                    attachmentCount: request.attachments.length,
                },
            });
        } catch (e) {
            telemetryClient.trackCustomEvent({
                name: TelemetryEventName.AskHRTicketFailed,
                properties: {
                    UsageEventType: UsageEventType.UserAction,
                    UsageCapabilityName: UsageCapabilityName.AskHRBot,
                    UsageSubCapabilityName: UsageSubCapabilityName.AskHRTicket,
                    UsageEventName: UsageEventName.WebChatMessage,
                    errorMessage: e.message,
                },
            });

            telemetryClient.trackException(e);
            setServerError(e.message);
        } finally {
            setSubmitting(false);
            Spinner.hide();

            setTimeout(() => {
                TextInputDisabler.enable();
            });
        }
    };

    const handleCanceled = () => {
        sendHiddenMessageToBot(dispatch, 'Cancel');

        setTimeout(() => {
            AskHRFormContext.hide(activityId, false);
            TextInputDisabler.enable();
        });
    };

    const handleBrokerChanged = (_: React.FormEvent, option: IChoiceGroupOption) => {
        setRequest({ ...request, topic: option.key });
    };

    const options: IChoiceGroupOption[] = [
        { key: 'benefits', text: 'Benefits, Time or Leave' },
        { key: 'recruiting', text: 'Recruiting', styles: { field: { marginLeft: "25px" } } },
        { key: 'other', text: 'Other', styles: { field: { marginLeft: "25px" } } }
    ];

    return (
        <Styled.Root>
            <Label>
                To help AskHR fully understand your issue, please share more details for your
                ticket.
            </Label>

            {disableFlag && (<Label required>
                Please confirm if your issue is related to one of the following topic:
            </Label>)}

            {serverError && (
                <InputGroup>
                    <MessageBar messageBarType={MessageBarType.error}>{serverError}</MessageBar>
                </InputGroup>
            )}

            {disableFlag && (
                <InputGroup>
                    <ChoiceGroup
                        componentRef={choiceRef}
                        required={true}
                        onChange={(_: React.FormEvent, option: IChoiceGroupOption) => { handleBrokerChanged(_, option) }}
                        options={options}
                        styles={{
                            flexContainer: { display: 'flex' },
                        }} />
                    {optionError.isVald && (
                        <Stack  {...optionValidatorText}>
                            {helperText}
                        </Stack>
                    )}
                </InputGroup>
            )}

            <InputGroup>
                <Label required>Subject</Label>
                <TextField
                    componentRef={ref}
                    name="subject"
                    ariaLabel="subject"
                    aria-roledescription="edit required"
                    onChange={handleChanged}
                    usageEvent={{
                        feature: UsageCapabilityName.AskHRBot,
                        subFeature: UsageSubCapabilityName.AskHRTicket,
                    }}
                    value={request.subject}
                    disabled={isSubmitting}
                    errorMessage={validationErrors.subject ? 'Subject is required.' : ''}
                    description={`${subjectCharacterLimit} character limit`}
                />
            </InputGroup>

            <InputGroup>
                <Label required>Type more details, optionally add attachment(s).</Label>
                <TextField
                    name="description"
                    componentRef={refDescription}
                    multiline
                    rows={10}
                    autoAdjustHeight
                    resizable={false}
                    onChange={handleChanged}
                    ariaLabel={'details'}
                    aria-roledescription={'edit required ' + descriptionTemplate}
                    usageEvent={{
                        feature: UsageCapabilityName.AskHRBot,
                        subFeature: UsageSubCapabilityName.AskHRTicket,
                    }}
                    disabled={isSubmitting}
                    errorMessage={validationErrors.description ? 'Description is required.' : ''}
                    value={incidentDescription}
                />
            </InputGroup>

            <AskHRFormAttachments
                value={request.attachments}
                onChange={handleChanged}
                name="attachments"
                errorMessage={validationErrors.attachments ? 'Attachments are invalid.' : ''}
            />

            {disableFlag && (
                <Checkbox
                    name={'chkHighImportance'}
                    label=""
                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
                        setRequest((p) => ({
                            ...p,
                            ["highPriority"]: isChecked,
                        }));;

                        const linkEvent: UserEvent = {
                            feature: 'AskHRSupport',
                            subFeature: 'Home.CreateCase.HighImportanteCheckbox',
                            featureLocation: 'Home',
                            eventName: UsageEventName.CheckBoxChanged,
                            type: EventType.User,
                        };
                        telemetryClient.trackEvent(linkEvent);
                    }}

                    checked={request.highPriority}

                    onRenderLabel={() => {
                        return (
                            <>
                                <CheckboxDescription>
                                    High importance
                                    <br />
                                    <span style={{ fontWeight: 400 }}>
                                        I need help to resolve my issue quickly and
                                        would like a response as soon as possible.
                                    </span>
                                </CheckboxDescription>
                            </>
                        );
                    }}
                    styles={{
                        label: {
                            alignItems: 'flex-start',
                            marginBottom: 15,
                        },
                    }}
                    usageEvent={{
                        feature: '',
                        subFeature: '',
                    }}
                />)}

            <InputGroup>
                <Styled.Disclaimer>
                    By clicking Submit, you acknowledge and agree to the terms and conditions set
                    forth in the{' '}
                    <Styled.Link href={__DPN_LINK__} target="_blank">
                        Microsoft Data Privacy Notice
                    </Styled.Link>
                    .
                </Styled.Disclaimer>
            </InputGroup>

            <InputGroup>
                <Styled.SubmitButton
                    title="Submit"
                    usageEvent={{
                        feature: UsageCapabilityName.AskHRBot,
                        subFeature: UsageSubCapabilityName.AskHRTicket,
                    }}
                    onClick={handleSubmitted}
                    disabled={isSubmitting}
                >
                    Submit
                </Styled.SubmitButton>

                <DefaultButton
                    title="Cancel"
                    usageEvent={{
                        feature: UsageCapabilityName.AskHRBot,
                        subFeature: UsageSubCapabilityName.AskHRTicket,
                    }}
                    disabled={isSubmitting}
                    onClick={handleCanceled}
                >
                    Cancel
                </DefaultButton>
            </InputGroup>
        </Styled.Root>

    );
}
