import { Action, Dispatch } from 'botframework-webchat';
import { CustomProperties } from '@employee-experience/common/lib/Models';
import {
    ConversationEvent,
    ConversationTurn,
    ConversationEventSubscriber,
    IConversationContext,
    IConversationContextUpdateModel,
} from './ConversationContext.types';
import { BotActionType } from '../../AskHRBot.types';

class ConversationContext {
    private dispatch: Dispatch;
    private sessionLength = 1000 * 60 * 20;
    private enableSpinnerOnText: string[] = ['Looking for answers...'];
    private disableTextBoxOnText: string[] = [
        'Rephrase',
        'Yes',
        'None of these',
        'None of these/ask a different question',
        'I am a close contact'
    ];
    private disableTextBoxOnIncomingText: string[] = [
        "You don't have right permissions to proceed.",
        '{BlockInputBox}',
    ];
    private disableTextBoxOnFeedBack: string[] = [
        'Great! Thank you for visiting the AskHR Virtual Assistant. Please rate your experience.',
    ];
    private conversationContext: IConversationContext = {
        turn: ConversationTurn.Unknown,
        startTime: null,
        event: ConversationEvent.None,
        lastUserActionTime: null,
        lastBotActionTime: null,
        lastActivityDetails: null,
    };

    private eventSubscribers: {
        [key: string]: { [key: string]: ConversationEventSubscriber };
    } = {};

    public constructor() {
        setInterval(() => {
            if (!this.conversationContext.startTime) return;
            const now = new Date().getTime();
            const lastAction =
                this.conversationContext.lastUserActionTime || this.conversationContext.startTime;

            if (__IS_DEVELOPMENT__) {
                const elapsed = now - lastAction.getTime();
                const sessionLeft = (this.sessionLength - elapsed) / 1000 / 60;
                console.log(`Session timeout in ${sessionLeft.toFixed(1)} mins`);
            }

            if (now - lastAction.getTime() > this.sessionLength) {
                const nextContext = {
                    ...this.conversationContext,
                    event: ConversationEvent.SessionTimedOut,
                };

                if (this.eventSubscribers[ConversationEvent.SessionTimedOut]) {
                    for (const key in this.eventSubscribers[ConversationEvent.SessionTimedOut]) {
                        this.eventSubscribers[ConversationEvent.SessionTimedOut][key](
                            this.conversationContext,
                            nextContext
                        );
                    }
                }

                this.updateConversationContext({
                    event: ConversationEvent.SessionTimedOut,
                    lastActivityDetails: this.conversationContext.lastActivityDetails,
                });
            }
        }, 1000 * 60);
    }

    public subscribe(id: string, event: ConversationEvent, callback: ConversationEventSubscriber) {
        this.eventSubscribers[event] = this.eventSubscribers[event] || {};
        this.eventSubscribers[event][id] = callback;
    }

    public unsubscribe(id: string, event: ConversationEvent) {
        if (!this.eventSubscribers[event]) return;
        if (!this.eventSubscribers[event][id]) return;

        delete this.eventSubscribers[event][id];
    }

    public getConversationContext(): IConversationContext {
        return this.conversationContext;
    }

    public trackActions(action: Action) {
        const activityDetail = this.getActivityDetail(action);
        const turn = this.conversationContext.turn;
        let isUnAuthorizedAccess = false;

        if (
            this.enableSpinnerOnText.indexOf(action.payload?.activity?.text) != -1 &&
            turn === 'Bot' &&
            action.payload.activity.from.role !== 'user'
        ) {
            this.updateConversationContext({
                event: ConversationEvent.AdhocFlowConversation,
                startTime: new Date(),
                lastActivityDetails: activityDetail,
            });
        }

        if (action.payload?.activity?.suggestedActions?.actions != undefined) {
            for (let i = 0; i < this.disableTextBoxOnText.length; i++) {
                if (
                    action.payload.activity.suggestedActions.actions.some(
                        (x) => x.title === this.disableTextBoxOnText[i]
                    )
                ) {
                    this.updateConversationContext({
                        event: ConversationEvent.UserOnlySelectOptions,
                        startTime: new Date(),
                        lastActivityDetails: activityDetail,
                        turn: ConversationTurn.User,
                    });
                    break;
                }
            }
        }

        if (
            this.disableTextBoxOnFeedBack.indexOf(action.payload?.activity?.text) != -1 &&
            turn === 'Bot' &&
            action.payload.activity.from.role !== 'user'
        ) {
            this.updateConversationContext({
                event: ConversationEvent.UserOnlySelectOptions,
                startTime: new Date(),
                lastActivityDetails: activityDetail,
                turn: ConversationTurn.User,
            });
        }

        //disable text box if message is incoming from bot
        if (
            action.payload &&
            action.payload.activity &&
            action.payload.activity.type === 'message' &&
            this.disableTextBoxOnIncomingText.indexOf(action.payload?.activity?.text) != -1 &&
            action.payload.activity.from.role !== 'user'
        ) {
            isUnAuthorizedAccess = true;
            this.updateConversationContext({
                event: ConversationEvent.UnAuthorizedAccess,
                startTime: new Date(),
                lastActivityDetails: activityDetail,
            });
        }

        if (this.conversationContext.startTime === null) {
            this.updateConversationContext({
                event: ConversationEvent.Initialized,
                startTime: new Date(),
                lastActivityDetails: activityDetail,
            });
        }
        if (this.conversationContext.event === ConversationEvent.SessionTimedOut) {
            this.updateConversationContext({
                event: ConversationEvent.Initialized,
                startTime: new Date(),
                lastActivityDetails: activityDetail,
            });
        }
        if (
            action.type === BotActionType.INCOMING_ACTIVITY &&
            action.payload &&
            action.payload.activity &&
            action.payload.activity.type === 'message' &&
            action.payload.activity.text &&
            action.payload.activity.text === '{AskHRTicketForm}' &&
            action.payload.activity.from && 
            action.payload.activity.from.role !== 'user' &&
            !isUnAuthorizedAccess
        ){
            return this.updateConversationContext({
                event: ConversationEvent.BotRespondedToUserWithTktForm,
                turn: ConversationTurn.User,
                lastBotActionTime: new Date(),
                lastActivityDetails: activityDetail,
            });
        }
        if (
            action.type === BotActionType.INCOMING_ACTIVITY &&
            action.payload &&
            action.payload.activity &&
            action.payload.activity.type === 'message' &&
            action.payload.activity.text &&
            action.payload.activity.from.role !== 'user' &&
            !isUnAuthorizedAccess
        ) {
            switch (turn) {
                case ConversationTurn.Unknown:
                    return this.updateConversationContext({
                        event: ConversationEvent.BotInitiatedConversation,
                        turn: ConversationTurn.User,
                        lastBotActionTime: new Date(),
                        lastActivityDetails: activityDetail,
                    });
                case ConversationTurn.Bot:
                    return this.updateConversationContext({
                        event: ConversationEvent.BotRespondedToUser,
                        turn: ConversationTurn.User,
                        lastBotActionTime: new Date(),
                        lastActivityDetails: activityDetail,
                    });
                case ConversationTurn.User:
                    return this.updateConversationContext({
                        event: ConversationEvent.BotSentAdditionalMessage,
                        turn: ConversationTurn.User,
                        lastBotActionTime: new Date(),
                        lastActivityDetails: activityDetail,
                    });
            }
        }

        if (action.type === BotActionType.SEND_MESSAGE || action.type === BotActionType.POST_BACK) {
            switch (turn) {
                case ConversationTurn.Unknown:
                    return this.updateConversationContext({
                        event: ConversationEvent.UserInitiatedConversation,
                        turn: ConversationTurn.Bot,
                        lastUserActionTime: new Date(),
                        lastActivityDetails: activityDetail,
                    });
                case ConversationTurn.User:
                    return this.updateConversationContext({
                        event: ConversationEvent.UserRespondedToBot,
                        turn: ConversationTurn.Bot,
                        lastUserActionTime: new Date(),
                        lastActivityDetails: {
                            ...(action.type === BotActionType.SEND_MESSAGE && {
                                conversationId:
                                    this.conversationContext.lastActivityDetails?.conversationId,
                                from: 'user',
                            }),
                            ...(action.type === BotActionType.POST_BACK && {
                                ...this.conversationContext.lastActivityDetails,
                                ...action.payload,
                            }),
                        },
                    });
                case ConversationTurn.Bot:
                    return this.updateConversationContext({
                        event: ConversationEvent.UserSentAdditionalMessage,
                        turn: ConversationTurn.Bot,
                        lastUserActionTime: new Date(),
                        lastActivityDetails: activityDetail,
                    });
            }
        }
    }

    public setDispatch(dispatch: Dispatch) {
        this.dispatch = dispatch;
    }

    public getDispatch(): Dispatch {
        return this.dispatch;
    }

    private updateConversationContext(update: IConversationContextUpdateModel): void {
        const prev = this.conversationContext;

        this.conversationContext = {
            ...this.conversationContext,
            ...update,
        };

        for (const key in this.eventSubscribers[this.conversationContext.event]) {
            this.eventSubscribers[this.conversationContext.event][key](
                prev,
                this.conversationContext
            );
        }

        if (__IS_DEVELOPMENT__) {
            console.group(this.conversationContext.event);
            console.log(this.conversationContext);
            console.groupEnd();
        }
    }

    private getActivityDetail(action: Action): CustomProperties {
        const details: CustomProperties = {};
        if (!action || !action.payload || !action.payload.activity) return details;

        details.activityId = action.payload.activity.id;
        details.replyToActivityId = action.payload.activity.replyToId;
        details.activityType = action.payload.activity.type;
        details.activityTimeStamp = action.payload.activity.timestamp;
        details.activityName = action.payload.activity.name;

        if (action.payload.activity.conversation) {
            details.conversationId = action.payload.activity.conversation.id;
        }

        if (action.payload.activity.from) {
            details.from = action.payload.activity.from.role;
        }

        return details;
    }
}

const context = new ConversationContext();
export { context as ConversationContext };
