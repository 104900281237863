import { useContext, useEffect, useState } from 'react';
import {
    TelemetryEventName,
    UsageCapabilityName,
    UsageEventName,
    UsageEventType,
    UsageSubCapabilityName,
} from '../Resources';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';

const graphBase = 'https://graph.microsoft.com/v1.0';
const photoPath = '/me/photo/$value';

export function useUserPhoto(): [string | null, boolean] {
    const [photo, setPhoto] = useState<string | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const { graphClient, telemetryClient, authClient } = useContext(ComponentContext);

    useEffect(() => {
        setLoading(true);

        telemetryClient.trackCustomEvent({
            name: TelemetryEventName.UserPhotoRequested,
            properties: {
                UsageEventType: UsageEventType.SystemAction,
                UsageEventName: UsageEventName.DependencyRequested,
                UsageCapabilityName: UsageCapabilityName.MicrosoftGraph,
                UsageSubCapabilityName: UsageSubCapabilityName.Get,
            },
        });

        authClient
            .getUserId()
            .then((userId: string | null) => {
                if (userId) {
                    graphClient
                        .getPhoto(userId)
                        .then((photo) => {
                            telemetryClient.trackCustomEvent({
                                name: TelemetryEventName.UserPhotoReceived,
                                properties: {
                                    UsageEventType: UsageEventType.SystemAction,
                                    UsageEventName: UsageEventName.DependencyRequestSucceeded,
                                    UsageCapabilityName: UsageCapabilityName.MicrosoftGraph,
                                    UsageSubCapabilityName: UsageSubCapabilityName.Get,
                                },
                            });

                            setPhoto(photo);
                            setLoading(false);
                        })
                        .catch((error) => {
                            telemetryClient.trackException(error, {
                                UsageEventType: UsageEventType.SystemAction,
                                UsageEventName: UsageEventName.DependencyRequestFailed,
                                UsageCapabilityName: UsageCapabilityName.MicrosoftGraph,
                                UsageSubCapabilityName: UsageSubCapabilityName.Get,
                                requestUrl: `${graphBase}${photoPath}`,
                            });

                            setPhoto(null);
                            setLoading(false);
                        });
                } else {
                    setPhoto(null);
                    setLoading(false);
                }
            })
            .catch((error) => {
                telemetryClient.trackException(error, {
                    UsageEventType: UsageEventType.SystemAction,
                    UsageEventName: UsageEventName.DependencyRequestFailed,
                    UsageCapabilityName: UsageCapabilityName.MicrosoftGraph,
                    UsageSubCapabilityName: UsageSubCapabilityName.Get,
                    requestUrl: `${graphBase}${photoPath}`,
                });

                setPhoto(null);
                setLoading(false);
            });
    }, []);

    return [photo, isLoading];
}
