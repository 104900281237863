import { Spinner } from '../Spinner/Spinner';
import { IDirectLine, Activity, Action, Next } from 'botframework-webchat';
import {
    TelemetryEventName,
    UsageCapabilityName,
    UsageEventName,
    UsageEventType,
    UsageSubCapabilityName,
} from '../Resources';
import { CustomProperties } from '@employee-experience/common/lib/Models';
import { IAppContext } from '../Models/IAppContext';
import { randomId } from '../Helpers/getRandomId';

export function shouldTriggerTokenExchangeAction(activity: Activity): boolean {
    return (
        activity &&
        activity.from &&
        activity.from.role === 'bot' &&
        activity.attachments &&
        activity.attachments[0] &&
        activity.attachments[0].contentType === 'application/vnd.microsoft.card.oauth' &&
        activity.attachments[0].content.tokenExchangeResource
    );
}

function exchangeToken(
    appContext: IAppContext,
    directLine: IDirectLine,
    activity: Activity,
    token: string
): Promise<boolean> {
    const { authClient, telemetryClient } = appContext;

    return new Promise(async (resolve) => {
        const startTime = new Date().getTime();
        const user = await authClient.getUser();

        await directLine
            .postActivity({
                type: 'invoke',
                name: 'signin/tokenExchange',
                value: {
                    id: activity.attachments[0].content.tokenExchangeResource.id,
                    connectionName: activity.attachments[0].content.connectionName,
                    token,
                },
                from: {
                    ...user,
                    id: randomId,
                    role: 'user',
                },
            })
            .subscribe((result: string): void => {
                const endTime = new Date().getTime();
                const isSuccessful = result !== 'retry';

                telemetryClient.trackDependencyData({
                    id: 'PVATokenExchange',
                    name: 'PVATokenExchange',
                    success: isSuccessful,
                    responseCode: isSuccessful ? 200 : 500,
                    duration: endTime - startTime,
                });

                resolve(isSuccessful);
            });
    });
}

export function tokenExchangeAction(
    appContext: IAppContext,
    activity: Activity,
    next: Next,
    action: Action,
    directLine: IDirectLine
): void {
    const { authClient, telemetryClient } = appContext;
    const resourceUri = activity.attachments[0].content.tokenExchangeResource.uri;

    authClient
        .acquireToken([resourceUri])
        .then(async (token: string) => {
            telemetryClient.trackCustomEvent({
                name: TelemetryEventName.PVATokenExchangeRequested,
                properties: {
                    UsageEventName: UsageEventName.DependencyRequested,
                    UsageEventType: UsageEventType.SystemAction,
                    UsageCapabilityName: UsageCapabilityName.PVADirectLine,
                    UsageSubCapabilityName: UsageSubCapabilityName.PVATokenExchange,
                },
            });

            for (let retry = 0; retry < 3; retry++) {
                const isSuccessful = await exchangeToken(appContext, directLine, activity, token);
                if (isSuccessful) {
                    telemetryClient.trackCustomEvent({
                        name: TelemetryEventName.PVATokenExchangeSucceeded,
                        properties: {
                            UsageEventName: UsageEventName.DependencyRequestSucceeded,
                            UsageEventType: UsageEventType.SystemAction,
                            UsageCapabilityName: UsageCapabilityName.PVADirectLine,
                            UsageSubCapabilityName: UsageSubCapabilityName.PVATokenExchange,
                        },
                    });

                    break;
                }
                if (retry < 3) {
                    telemetryClient.trackCustomEvent({
                        name: TelemetryEventName.PVATokenExchangeRetried,
                        properties: {
                            UsageEventName: UsageEventName.DependencyRetried,
                            UsageEventType: UsageEventType.SystemAction,
                            UsageCapabilityName: UsageCapabilityName.PVADirectLine,
                            UsageSubCapabilityName: UsageSubCapabilityName.PVATokenExchange,
                            retry: retry.toString(),
                        },
                    });
                } else {
                    const failureMetrics: CustomProperties = {
                        UsageEventName: UsageEventName.DependencyRequestFailed,
                        UsageEventType: UsageEventType.SystemAction,
                        UsageCapabilityName: UsageCapabilityName.PVADirectLine,
                        UsageSubCapabilityName: UsageSubCapabilityName.PVATokenExchange,
                    };

                    telemetryClient.trackCustomEvent({
                        name: TelemetryEventName.PVATokenExchangeFailed,
                        properties: failureMetrics,
                    });

                    telemetryClient.trackException({
                        error: new Error(TelemetryEventName.PVATokenExchangeFailed),
                        properties: failureMetrics,
                    });

                    return next(action);
                }
            }
        })
        .catch(() => {
            return next(action);
        });
}
