import { Dispatch, Activity, Action, Next } from 'botframework-webchat';
import { AdhocFlowContext } from '../AdhocFlowContext/AdhocFlowContext';
import {
    TelemetryEventName,
    UsageCapabilityName,
    UsageEventName,
    UsageEventType,
    UsageSubCapabilityName,
} from '../Resources';
import { IAppContext } from '../Models/IAppContext';
import { sendHiddenMessageToBot } from './ConversationDispatchAction';

//When connected to bot and the connection is established, this action will initiate.
export function botConnectionFulfilledAction(): void {
    const getAdhocFlowContext = AdhocFlowContext.getAdhocFlowContext();
    getAdhocFlowContext.isAdhocFlowStarted = false;
    getAdhocFlowContext.isAdhocFlowFailed = false;
    getAdhocFlowContext.isAdhocFlowClosed = false;
    getAdhocFlowContext.ignoreText = false;
    getAdhocFlowContext.userQuestionForSearch = '';
    getAdhocFlowContext.botOrigin = '';
    AdhocFlowContext.updateAdhocFlowContext(getAdhocFlowContext);
}

//When connected to bot and the connection is established, this action will initiate.
export function setBotOriginIndicator(): string {
    const getAdhocFlowContext = AdhocFlowContext.getAdhocFlowContext();
    const query = new URLSearchParams(window.location.search);
    const origin = 'botOrigin';
    let botOrigin = query.get(origin.toLowerCase()) || query.get(origin);
    if (botOrigin === '' || botOrigin === null || botOrigin === undefined) {
        botOrigin = window.sessionStorage.getItem('botOriginKey');
        if (
            botOrigin === '' ||
            botOrigin === 'null' ||
            botOrigin === null ||
            botOrigin === undefined
        )
            botOrigin = 'default';
    }
    getAdhocFlowContext.botOrigin = botOrigin;
    AdhocFlowContext.updateAdhocFlowContext(getAdhocFlowContext);
    return botOrigin;
}

export function botIncomingActivityAdhocFlow(
    next: Next,
    action: Action,
    dispatch: Dispatch,
    activity: Activity,
    appContext: IAppContext
): void {
    const getAdhocFlowContext = AdhocFlowContext.getAdhocFlowContext();
    const { telemetryClient } = appContext;

    if (activity.text === '{GetBotOrigin}') {
        activity.text = '';
        sendHiddenMessageToBot(dispatch, getAdhocFlowContext.botOrigin);
    } else if (activity.text === '{BlockInputBox}') {
        activity.text = '';
    } else if (activity.text === "You don't have right permissions to proceed.") {
        //const getAdhocFlowContext = AdhocFlowContext.getAdhocFlowContext();
        const botOriginVal = getAdhocFlowContext.botOrigin;

        telemetryClient.trackCustomEvent({
            name: TelemetryEventName.BotSecurity,
            properties: {
                UsageEventName: UsageEventName.UserAuthorization,
                UsageEventType: UsageEventType.SystemAction,
                UsageCapabilityName: UsageCapabilityName.Platform,
                UsageSubCapabilityName: UsageSubCapabilityName.UserUnAuthorized,
                BotOrigin: botOriginVal,
            },
        });
    }

    return next(action);
}
