export enum CasesEnums {
    REQUEST_CASES = 'REQUEST_CASES',
    RECEIVE_CASES = 'RECEIVE_CASES',
    REQUEST_CASES_FAILED = 'REQUEST_CASES_FAILED',
}

export enum SubmitCaseEnums {
    SUBMIT_CASE = 'SUBMIT_CASE',
    SUBMIT_CASE_SUCCEDED = 'SUBMIT_CASE_SUCCEDED',
    SUBMIT_CASE_FAILED = 'SUBMIT_CASE_FAILED',
}

export enum CategoriesEnums {
    REQUEST_CATEGORIES = 'REQUEST_CATEGORIES',
    RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES',
    REQUEST_CATEGORIES_FAILED = 'REQUEST_CATEGORIES_FAILED',
}

export enum SubmitRequestActionEnums {
    SUBMIT_REQUEST_ACTION = 'SUBMIT_REQUEST_ACTION',
    SUBMIT_RECEIVE_ACTION = 'SUBMIT_RECEIVE_ACTION',
    SUBMIT_REQUEST_ACTION_FAILED = 'SUBMIT_REQUEST_ACTION_FAILED',
}

export enum PermissionsEnums {
    REQUEST_PERMISSIONS = 'REQUEST_PERMISSIONS',
    REQUEST_PERMISSIONS_FAILED = 'REQUEST_PERMISSIONS_FAILED',
    RECEIVE_PERMISSIONS = 'RECEIVE_PERMISSIONS',
}

export enum SearchEnums {
    REQUEST_HRWEB_SEARCH_RESULT = 'REQUEST_HRWEB_SEARCH_REUSLT',
    RECEIVE_HRWEB_SEARCH_RESULT = 'RECEIVE_HRWEB_SEARCH_REUSLT',
    FAILED_HRWEB_SEARCH_RESULT = 'FAILED_HRWEB_SEARCH_RESULT',
    REQUEST_BESTBET_SEARCH_RESULT = 'REQUEST_BESTBET_SEARCH_REUSLT',
    RECEIVE_BESTBET_SEARCH_RESULT = 'RECEIVE_BESTBET_SEARCH_REUSLT',
    FAILED_BESTBET_SEARCH_RESULT = 'FAILED_BESTBET_SEARCH_RESULT',
    REQUEST_MANAGER_HUB_SEARCH_RESULT = 'REQUEST_MANAGER_HUB_SEARCH_RESULT',
    RECEIVE_MANAGER_HUB_SEARCH_RESULT = 'RECEIVE_MANAGER_HUB_SEARCH_RESULT',
    FAILED_MANAGER_HUB_SEARCH_RESULT = 'FAILED_MANAGER_HUB_SEARCH_RESULT',
}
