import { DefaultButton, Dropdown, TextField } from '@employee-experience/common';
import { Colors } from '@employee-experience/common/lib/Colors';
import { Icon } from '@fluentui/react';
import { Label } from 'office-ui-fabric-react/lib/Label';

export const CreateCaseSubTitle = styled.label`
    font-size: 14px;
    line-height: 20px;
    color: #423f3f;
`;

export const Category1DropDown = styled(Dropdown)`
    margin-top: 26px;
`;

export const Category2DropDown = styled(Dropdown)`
    margin-top: 10px;
`;

export const SubjectField = styled(TextField)`
    margin-top: 24px;
`;

export const DetailsField = styled(TextField)`
    margin-top: 24px;
`;

export const AttachFileLink = styled(DefaultButton)`
    color: ${Colors.Primary};
    padding: 0;
    border: 0;
    margin-top: 0;
    text-align: left;
    font-size: 14px;
    line-height: 16px;
    min-width: 40px;
    margin-top: 10px;

    .ms-Button-label {
        font-weight: 400;
        width: 65px;
        min-width: 40px;
    }
`;

export const SubmitTicketInstruction = styled.label`
    margin-top: 15px;
    margin-bottom: 15px;
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: #646464;
    max-width: 520px;
`;

export const SubmitTicketFooter = styled.label`
    margin-top: 30px;
    margin-bottom: 15px;
    display: block;
    font-size: 12px;
    line-height: 20px;
    color: #646464;
    max-width: 520px;
`;

export const CheckboxDescription = styled.p`
    padding-left: 5px;
    font-weight: 600;
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: #171717;
    max-width: 520px;
`;

export const SubmitButtonsContainer = styled.div`
    margin-bottom: 15px;
    display: block;
`;

export const CategoriesSpinnerContainer = styled.div`
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
`;

export const SubmittingSpinnerContainer = styled.div`
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
`;

export const FormErrorValidationMessage = styled(Label)`
    font-size: 11px;
    color: red;
    font-weight: 600;
    margin: 0;
`;

export const AttachmentIcon = styled(Icon)`
    margin-right: 5px;
`;

export const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
`;

export const ErrorLoadingCategories = styled.img`
    width: 100px;
    height: 90px;
    margin-bottom: 10px;
`;
