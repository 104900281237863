import { DefaultButton } from '@employee-experience/common/lib/DefaultButton';
import { InputGroup } from '@employee-experience/common/lib/InputGroup';
import * as React from 'react';
import { IFile } from '../../../Models/IFile';
import { AskHRFormAttachmentItem } from './AskHRFormAttachmentItem';
import { IAskHRFormAttachmentsProps } from './AskHRFormAttachments.types';
import * as Styled from './AskHRFormAttachments.styled';
import { readFile } from './AskHRFormAttachments.utils';
import { IButton } from '@fluentui/react/lib/components/Button';

export function AskHRFormAttachments(props: IAskHRFormAttachmentsProps): React.ReactElement {
    const { name, onChange, value, errorMessage } = props;

    const ref = React.useRef<HTMLInputElement>();
    const attachFileButtonRef = React.useRef<IButton>();

    const handleAttachFileClicked = () => {
        if (ref.current) ref.current.click();
    };

    const handleFileChanged = () => {
        if (!ref.current) return;
        if (ref.current?.files?.length === 0) return;

        const file = ref.current!.files![0];

        readFile(file)
            .then((result) => {
                onChange(name, [...value, result]);
                attachFileButtonRef.current.focus();
            })
            .catch(() => alert('Failed to read file'))
            .finally(() => {});
    };

    const handleDeleted = (file: IFile) => {
        onChange(
            name,
            value.filter((p) => p !== file)
        );
        attachFileButtonRef.current.focus();
    };

    return (
        <Styled.Root>
            <Styled.AttachmentHint>
                Upload as many as 3 files in these formats: .png, .jpg, .jpeg, .txt, .docx, .doc,
                .xls, .xlsx, .ppt, .pptx, .pdf, .msg. Maximum total file size is 8MB.
            </Styled.AttachmentHint>
            <InputGroup>
                <DefaultButton
                    componentRef={attachFileButtonRef}
                    title="Attach files"
                    aria-label="Attach files"
                    aria-roledescription="button"
                    onClick={handleAttachFileClicked}
                    usageEvent={{
                        feature: '',
                        subFeature: '',
                    }}
                    disabled={value.length >= 3}
                >
                    Attach files
                </DefaultButton>
            </InputGroup>

            <Styled.AttachmentList>
                {value.map((p) => (
                    <AskHRFormAttachmentItem file={p} onDelete={handleDeleted} key={p.name} />
                ))}
            </Styled.AttachmentList>

            {errorMessage && <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>}

            <Styled.HiddenInput
                ref={ref}
                type="file"
                onChange={handleFileChanged}
                // list of allowed extensions for file picking
                // validation list is in `src\AskHRBot\Activities\AskHRFormActivity\AskHRForm\AskHRForm.tsx`
                // any updates to this list should be replicated there & vice versa
                accept=".png, .jpg, .jpeg, .txt, .docx, .doc,
                .xls, .xlsx, .ppt, .pptx, .pdf, .msg"
            />
        </Styled.Root>
    );
}
