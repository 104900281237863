import * as React from 'react';
import { Card, ICustomActivityHandlerProps } from 'botframework-webchat';
import { ITelemetryClient } from '@employee-experience/common/lib/Models';
import { getSanitizedHtml } from '../../Helpers/getSanitizedHtml';
import * as Styled from './TempLinkActivity.styled';
import {
    BotContentType,
    BotResponseType,
    IBestBetResponse,
    ISearchResponse,
    IBestBetResult,
    ISearchResult,
} from '../../AskHRBot.types';
import {
    TelemetryEventName,
    UsageCapabilityName,
    UsageEventName,
    UsageEventType,
    UsageSubCapabilityName,
} from '../../Resources';
import { IAppContext } from '../../Models/IAppContext';
import { normalizeUrl } from '../../Helpers/normalizeUrl';

function getBotResponseType(response: IBestBetResponse | ISearchResponse | {}): BotResponseType {
    if (response.hasOwnProperty('BestBetResults')) {
        return BotResponseType.BestBet;
    }

    if (response.hasOwnProperty('SearchResults')) {
        return BotResponseType.Search;
    }

    return BotResponseType.Unknown;
}

function renderErrorMessage(
    telemetryClient: ITelemetryClient,
    conversationId?: string
): () => React.ReactElement {
    return function errorMessageRenderer(): React.ReactElement {
        telemetryClient.trackCustomEvent({
            name: TelemetryEventName.UnknownBotResponseType,
            properties: {
                UsageEventType: UsageEventType.SystemAction,
                UsageEventName: UsageEventName.ValidationFailed,
                UsageCapabilityName: UsageCapabilityName.AskHRBot,
                UsageSubCapabilityName: UsageSubCapabilityName.BotResponse,
                conversationId,
            },
        });

        return (
            <Styled.Root>
                <Styled.NoData>
                    Sorry, something went wrong while processing your request. Please try again
                    later
                </Styled.NoData>
            </Styled.Root>
        );
    };
}

function renderNoResponseMessage(
    telemetryClient: ITelemetryClient,
    conversationId?: string
): () => React.ReactElement {
    return function NoResponseMessageRender(): React.ReactElement {
        React.useEffect(() => {
            telemetryClient.trackCustomEvent({
                name: TelemetryEventName.EmptyBotResponse,
                properties: {
                    UsageEventType: UsageEventType.SystemAction,
                    UsageEventName: UsageEventName.ValidationFailed,
                    UsageCapabilityName: UsageCapabilityName.AskHRBot,
                    UsageSubCapabilityName: UsageSubCapabilityName.BotResponse,
                    conversationId,
                },
            });
        }, []);

        return (
            <Styled.Root>
                <Styled.NoData>
                    Sorry, I could not find anything. Please try again with a different term
                </Styled.NoData>
            </Styled.Root>
        );
    };
}

function BestBetResultRenderer(props: {
    response: IBestBetResponse;
    telemetryClient: ITelemetryClient;
    conversationId?: string;
}): React.ReactElement {
    const { response, telemetryClient, conversationId } = props;
    let bestBetResponses: IBestBetResult[] = response.BestBetResults;
    const botResponses = response.BestBetResults.filter(
        (r) => r.ContentType === BotContentType.BotItem
    );
    const isBotResponses = botResponses && botResponses.length > 0;
    if (isBotResponses) bestBetResponses = botResponses;

    React.useEffect(() => {
        let isImpersonated = false;
        if (response.ImpersonatedAlias && response.ImpersonatedAlias !== 'undefined')
            isImpersonated = true;
        telemetryClient.trackCustomEvent({
            name: TelemetryEventName.FlowResponseReceived,
            properties: {
                IsImpersonated: isImpersonated,
                TargetCompanyCode: response.TargetCompanyCode,
                ImpersonatedRole: isImpersonated ? response.ImpersonatedRole : '',
                ImpersonatedAlias: isImpersonated ? response.ImpersonatedAlias : '',
                UsageEventType: UsageEventType.SystemAction,
                UsageEventName: UsageEventName.ValidationFailed,
                UsageCapabilityName: UsageCapabilityName.AskHRBot,
                UsageSubCapabilityName: UsageSubCapabilityName.BotResponse,
                responseType: isBotResponses ? 'BotItem' : 'BestBet',
                responseCount: bestBetResponses.length.toString(),
                conversationId,
            },
        });
    }, []);

    return (
        <Styled.Root>
            <Styled.List>
                {bestBetResponses.map((response, index) => {
                    const { URL, Title, BOTResponse, DocumentDescription } = response;

                    return (
                        <Styled.ListItem key={index}>
                            {URL && (
                                <Styled.TitleLink
                                    target="_blank"
                                    href={normalizeUrl(URL)}
                                    rel="external"
                                    // Prevent SharePoint Router from intercepting
                                    data-interception="off"
                                >
                                    {Title}
                                </Styled.TitleLink>
                            )}
                            {!URL && <Styled.TitleText>{Title}</Styled.TitleText>}

                            <Styled.ListItemDescription
                                className="askhr-bot-item"
                                aria-label={DocumentDescription}
                                dangerouslySetInnerHTML={{
                                    __html: getSanitizedHtml(BOTResponse) || DocumentDescription,
                                }}
                            />
                        </Styled.ListItem>
                    );
                })}
            </Styled.List>
        </Styled.Root>
    );
}

function BotResponseRenderer(props: {
    response: string;
    conversationId?: string;
}): React.ReactElement {
    const { response } = props;

    return (
        <Styled.Root dangerouslySetInnerHTML={{
            __html: getSanitizedHtml(response),
        }}>
        </Styled.Root>

    );
}

function SearchResultRenderer(props: {
    response: ISearchResponse;
    telemetryClient: ITelemetryClient;
    conversationId?: string;
}): React.ReactElement {
    const { response, telemetryClient, conversationId } = props;

    React.useEffect(() => {
        let isImpersonated = false;
        if (response.ImpersonatedAlias && response.ImpersonatedAlias !== 'undefined')
            isImpersonated = true;
        telemetryClient.trackCustomEvent({
            name: TelemetryEventName.FlowResponseReceived,
            properties: {
                IsImpersonated: isImpersonated,
                TargetCompanyCode: response.TargetCompanyCode,
                ImpersonatedRole: isImpersonated ? response.ImpersonatedRole : '',
                ImpersonatedAlias: isImpersonated ? response.ImpersonatedAlias : '',
                UsageEventType: UsageEventType.SystemAction,
                UsageEventName: UsageEventName.ValidationFailed,
                UsageCapabilityName: UsageCapabilityName.AskHRBot,
                UsageSubCapabilityName: UsageSubCapabilityName.BotResponse,
                responseType: 'Search',
                responseCount: response.SearchResults.length.toString(),
                conversationId,
            },
        });
    }, []);

    return (
        <Styled.Root>
            <Styled.List>
                {response.SearchResults.map((response, index) => {
                    const { Title, Path, Description, HitHighlightedSummary } = response;
                    return (
                        <Styled.ListItem key={index}>
                            {Path && (
                                <Styled.TitleLink
                                    rel="external"
                                    target="_blank"
                                    href={normalizeUrl(Path)}
                                    // Prevent SharePoint Router from intercepting
                                    data-interception="off"
                                >
                                    {Title}
                                </Styled.TitleLink>
                            )}
                            {!URL && <Styled.TitleText>{Title}</Styled.TitleText>}

                            <Styled.ListItemDescription
                                className="askhr-bot-item"
                                aria-label={HitHighlightedSummary}
                                dangerouslySetInnerHTML={{
                                    __html: Description || getSanitizedHtml(HitHighlightedSummary),
                                }}
                            />
                        </Styled.ListItem>
                    );
                })}
            </Styled.List>
        </Styled.Root>
    );
}

/* This is a temporary solution until PVA provides a better way to detect content type
 * refer to the OneNote for more information
 */
export function TempLinkActivity(
    appContext: IAppContext,
    card: Card
): (props: ICustomActivityHandlerProps) => React.ReactElement {
    const { telemetryClient } = appContext;
    const conversationId = card.activity.conversation?.id;
    const isRoleUser = card.activity.from?.role === 'user';

    let isJson = false;
    let json: IBestBetResponse | ISearchResponse | number | undefined | string = undefined;
    try {
        const escaped = card.activity.text
            .replace(/“/g, '\\"')
            .replace(/”/g, '\\"')
            .replace(/’/g, "'")
            .replace(/\n/g, '\\n')
            .replace(/\r/g, '\\r')
            .replace(/\t/g, '\\t')
            .replace(/\f/g, '\\f')
            .replace(/[^\u0000-\u007F]+/g, '');
        json = JSON.parse(escaped);
        isJson = true;
    } catch { }

    return ({ nextCard, children }): React.ReactElement => {

        if (card.activity.text && card.activity.text != '' && card.activity.text.indexOf('div.description') !== -1) {
            return nextCard(() => (
                <BotResponseRenderer
                    response={card.activity.text as string}
                />
            ));
        }
        else {
            return nextCard(children);
        }

        //To-Do: remove below code once content formatter engine changes are stabilized.

        // if (isRoleUser || !isJson || !isNaN(json as number)) return nextCard(children);
        // const botResponseType = getBotResponseType(json);
        // switch (botResponseType) {
        //     case BotResponseType.Unknown:
        //         return nextCard(renderErrorMessage(telemetryClient));
        //     case BotResponseType.BestBet:
        //         var bbresp: IBestBetResponse = json as IBestBetResponse;
        //         if (bbresp.BestBetResults.length === 0)
        //             return nextCard(renderNoResponseMessage(telemetryClient, conversationId));
        //         else
        //             return nextCard(() => (
        //                 <BestBetResultRenderer
        //                     response={json as IBestBetResponse}
        //                     telemetryClient={telemetryClient}
        //                     conversationId={conversationId}
        //                 />
        //             ));
        //     case BotResponseType.Search:
        //         return nextCard(() => (
        //             <SearchResultRenderer
        //                 response={json as ISearchResponse}
        //                 telemetryClient={telemetryClient}
        //                 conversationId={conversationId}
        //             />
        //         ));
        // }
    };
}
