import { useEffect } from 'react';
import { ConversationContext } from '../Context/ConversationContext';
import { ConversationEvent } from '../Context/ConversationContext/ConversationContext.types';
import { Spinner } from '../Spinner/Spinner';

const id = 'useSpinner';

export function useSpinner(): void {
    useEffect(() => {
        ConversationContext.subscribe(id, ConversationEvent.Initialized, () => Spinner.show());
        ConversationContext.subscribe(id, ConversationEvent.BotInitiatedConversation, () =>
            Spinner.hide()
        );
        ConversationContext.subscribe(id, ConversationEvent.BotRespondedToUser, () =>
            Spinner.hide()
        );
        ConversationContext.subscribe(id, ConversationEvent.BotRespondedToUserWithTktForm, () =>
            Spinner.hide()
        );
        ConversationContext.subscribe(id, ConversationEvent.BotSentAdditionalMessage, () =>
            Spinner.hide()
        );
        ConversationContext.subscribe(id, ConversationEvent.UserOnlySelectOptions, () =>
            Spinner.hide()
        );
        ConversationContext.subscribe(id, ConversationEvent.UnAuthorizedAccess, () =>
            Spinner.hide()
        );
        ConversationContext.subscribe(id, ConversationEvent.UserInitiatedConversation, () =>
            Spinner.show()
        );
        ConversationContext.subscribe(id, ConversationEvent.AdhocFlowConversation, () =>
            Spinner.show()
        );
        ConversationContext.subscribe(id, ConversationEvent.UserRespondedToBot, () =>
            Spinner.show()
        );
        ConversationContext.subscribe(id, ConversationEvent.UserSentAdditionalMessage, () =>
            Spinner.show()
        );

        return () => {
            ConversationContext.unsubscribe(id, ConversationEvent.Initialized);
            ConversationContext.unsubscribe(id, ConversationEvent.BotInitiatedConversation);
            ConversationContext.unsubscribe(id, ConversationEvent.BotRespondedToUser);
            ConversationContext.unsubscribe(id, ConversationEvent.AdhocFlowConversation);
            ConversationContext.unsubscribe(id, ConversationEvent.BotSentAdditionalMessage);
            ConversationContext.unsubscribe(id, ConversationEvent.UserInitiatedConversation);
            ConversationContext.unsubscribe(id, ConversationEvent.UserRespondedToBot);
            ConversationContext.unsubscribe(id, ConversationEvent.UserSentAdditionalMessage);
            ConversationContext.unsubscribe(id, ConversationEvent.UserOnlySelectOptions);
            ConversationContext.unsubscribe(id, ConversationEvent.UnAuthorizedAccess);
        };
    }, []);
}
