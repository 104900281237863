import { useContext, useEffect } from 'react';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import { ConversationContext } from '../Context/ConversationContext';
import {
    ConversationEvent,
    IConversationContext,
} from '../Context/ConversationContext/ConversationContext.types';
import { CustomProperties } from '@employee-experience/common/lib/Models';
import {
    TelemetryEventName,
    UsageCapabilityName,
    UsageEventName,
    UsageEventType,
    UsageSubCapabilityName,
} from '../Resources';

const id = 'useFeedbackTelemetry';
const feedbackMap: { [key: string]: string } = {
    '1': 'Terrible',
    '2': 'Poor',
    '3': 'Fair',
    '4': 'Good',
    '5': 'Excellent',
};

export function useFeedbackTelemetry(): void {
    const { telemetryClient } = useContext(ComponentContext);

    const handleUserRespondedToBot = (prev: IConversationContext, next: IConversationContext) => {
        if (!next.lastActivityDetails.value) return;

        const feedbackValues: CustomProperties = next.lastActivityDetails.value as CustomProperties;
        const key: string = Object.keys(next.lastActivityDetails.value)[0];
        const value: string = feedbackValues[key] as string;
        const feedbackValue = feedbackMap[value];

        if (!feedbackValue) return;

        telemetryClient.trackCustomEvent({
            name: TelemetryEventName.UserFeedbackProvided,
            properties: {
                UsageEventType: UsageEventType.UserAction,
                UsageEventName: UsageEventName.ButtonClick,
                UsageCapabilityName: UsageCapabilityName.Platform,
                UsageSubCapabilityName: UsageSubCapabilityName.Feedback,
                feedback: feedbackValue,
            },
        });
    };

    useEffect(() => {
        ConversationContext.subscribe(
            id,
            ConversationEvent.UserRespondedToBot,
            handleUserRespondedToBot
        );

        return () => {
            ConversationContext.unsubscribe(id, ConversationEvent.UserRespondedToBot);
        };
    }, []);
}
