import { useContext, useEffect, useState } from 'react';
import { createDirectLine, IDirectLine } from 'botframework-webchat';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import {
    TelemetryEventName,
    UsageCapabilityName,
    UsageEventName,
    UsageEventType,
    UsageSubCapabilityName,
} from '../Resources';

export function useDirectLine(): IDirectLine | null {
    const { telemetryClient } = useContext(ComponentContext);
    const [directLine, setDirectLine] = useState(null);

    useEffect(() => {
        telemetryClient.trackCustomEvent({
            name: TelemetryEventName.DirectLineTokenRequested,
            properties: {
                UsageEventType: UsageEventType.SystemAction,
                UsageEventName: UsageEventName.DependencyRequested,
                UsageCapabilityName: UsageCapabilityName.PVADirectLine,
                UsageSubCapabilityName: UsageSubCapabilityName.Get,
            },
        });

        let url: string;
        
        url = `${__PVA2_TOKEN_URL__}`;

        fetch(url)
            .then((r) => r.json())
            .then(({ token }) => {
                telemetryClient.trackCustomEvent({
                    name: TelemetryEventName.DirectLineTokenReceived,
                    properties: {
                        UsageEventType: UsageEventType.SystemAction,
                        UsageEventName: UsageEventName.DependencyRequestSucceeded,
                        UsageCapabilityName: UsageCapabilityName.PVADirectLine,
                        UsageSubCapabilityName: UsageSubCapabilityName.Get,
                    },
                });

                let newDirectLine;
                try {
                    newDirectLine = createDirectLine({ token });

                    telemetryClient.trackCustomEvent({
                        name: TelemetryEventName.DirectLineConnectionCreated,
                        properties: {
                            UsageEventType: UsageEventType.SystemAction,
                            UsageEventName: UsageEventName.DependencyRequestSucceeded,
                            UsageCapabilityName: UsageCapabilityName.PVADirectLine,
                            UsageSubCapabilityName: UsageSubCapabilityName.Get,
                        },
                    });
                } catch (e) {
                    telemetryClient.trackCustomEvent({
                        name: TelemetryEventName.DirectLineConnectionFailed,
                        properties: {
                            UsageEventType: UsageEventType.SystemAction,
                            UsageEventName: UsageEventName.DependencyRequestFailed,
                            UsageCapabilityName: UsageCapabilityName.PVADirectLine,
                            UsageSubCapabilityName: UsageSubCapabilityName.Get,
                        },
                    });
                    telemetryClient.trackException(e, {
                        UsageEventType: UsageEventType.SystemAction,
                        UsageEventName: UsageEventName.DependencyRequestFailed,
                        UsageCapabilityName: UsageCapabilityName.PVADirectLine,
                        UsageSubCapabilityName: UsageSubCapabilityName.Get,
                        requestUrl: url,
                    });
                }

                setDirectLine(newDirectLine);
            })
            .catch((e) => {
                telemetryClient.trackCustomEvent({
                    name: TelemetryEventName.DirectLineTokenRequestFailed,
                    properties: {
                        UsageEventType: UsageEventType.SystemAction,
                        UsageEventName: UsageEventName.DependencyRequestFailed,
                        UsageCapabilityName: UsageCapabilityName.PVADirectLine,
                        UsageSubCapabilityName: UsageSubCapabilityName.Get,
                    },
                });

                telemetryClient.trackException(e, {
                    UsageEventType: UsageEventType.SystemAction,
                    UsageEventName: UsageEventName.DependencyRequestFailed,
                    UsageCapabilityName: UsageCapabilityName.PVADirectLine,
                    UsageSubCapabilityName: UsageSubCapabilityName.Get,
                    requestUrl: url,
                });
            });
    }, []);

    return directLine;
}
