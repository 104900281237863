import { CustomProperties } from '@employee-experience/common/lib/Models';

export enum ConversationTurn {
    Unknown = 'Unknown',
    User = 'User',
    Bot = 'Bot',
}

export interface IConversationContext {
    event: ConversationEvent;
    turn: ConversationTurn;
    startTime: Date | null;
    lastUserActionTime: Date | null;
    lastBotActionTime: Date | null;
    lastActivityDetails: CustomProperties | null;
}

export interface IConversationContextUpdateModel {
    event: ConversationEvent;
    lastActivityDetails: CustomProperties;
    turn?: ConversationTurn;
    startTime?: Date;
    lastUserActionTime?: Date;
    lastBotActionTime?: Date;
}

export type ConversationEventSubscriber = (
    prev: IConversationContext,
    next: IConversationContext
) => void;

export enum ConversationEvent {
    None = 'None',
    Initialized = 'ConversationInitialized',
    BotInitiatedConversation = 'BotInitiatedConversation',
    UserInitiatedConversation = 'UserInitiatedConversation',
    BotRespondedToUser = 'BotRespondedToUser',
    BotRespondedToUserWithTktForm = 'BotRespondedToUserWithTktForm',
    BotSentAdditionalMessage = 'BotSentAdditionalMessage',
    UserRespondedToBot = 'UserRespondedToBot',
    UserSentAdditionalMessage = 'UserSentAdditionalMessage',
    SessionTimedOut = 'ConversationSessionTimedOut',
    AdhocFlowConversation = 'AdhocFlowConversation',
    UserOnlySelectOptions = 'UserOnlySelectOptions',
    UnAuthorizedAccess = 'UnAuthorizedAccess'
}
