import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import * as React from 'react';
import WebChat from 'botframework-webchat';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { withContext } from '@employee-experience/common/lib/withContext';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
import * as Styled from './AskHRBot.styled';
import { useDirectLine } from './Hooks/useDirectLine';
import { useLinkTelemetry } from './Hooks/useLinkTelemetry';
import { useSpinner } from './Hooks/useSpinner';
import { useTextInputDisabler } from './Hooks/useTextInputDisabler';
import { IAppContext } from './Models/IAppContext';
import { createStore, createActivityMiddleware } from './AskHRBot.store';
import { useUserPhoto } from './Hooks/useUserPhoto';
import { useConversationTelemetry } from './Hooks/useCoversationTelemetry';
import { useSessionTimeout } from './Hooks/useSessionTimeout';
import { TimeoutOverlay } from './TimeoutOverlay';
import { useFeedbackTelemetry } from './Hooks/useFeedbackTelemetry';
import { randomId } from './Helpers/getRandomId';
import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import { ILinkStyles, Link } from '@fluentui/react/lib/Link';
import renderAVAMarkdown from './renderAVAMarkdown';
import { useAskHrSupportApi } from './Hooks/useAskHrSupportApi';

initializeIcons();

function AskHRBot(): React.ReactElement {
    const { telemetryClient, authClient } = React.useContext(ComponentContext);
    const appContext: IAppContext = {
        telemetryClient,
        authClient,
    };

    useConversationTelemetry();
    useFeedbackTelemetry();
    useTextInputDisabler();
    useSpinner();
    useAskHrSupportApi();
    useLinkTelemetry();
    const directLine = useDirectLine();
    const [photo, isLoadingPhoto] = useUserPhoto();
    const isTimedOut = useSessionTimeout();

    const additionalStyles = { userAvatarImage: photo, hideUploadButton: true };

    const showWebChat = directLine && !isLoadingPhoto;
    const showLoading = !directLine || isLoadingPhoto;

    const linkStyles: ILinkStyles = {
        root: [
            {
                fontWeight: 600,
                lineHeight: '30px',
                color: '#0066b4',
            },
        ],
    };

    return (
        <Styled.Root id="askhrbot">
            <Styled.BotStyleOverrides />
            <MessageBar
                messageBarType={MessageBarType.info}
                styles={{
                    root: {
                        backgroundColor: '#D8EAF7',
                    },
                }}
            >
                View your
                <Link href="/support" target="_blank" title="AskHR Support Page">
                    case status
                </Link>{' '}
                and history.
            </MessageBar>
            <Styled.BotRoot tabIndex={0}>
                {showLoading && (
                    <Styled.SpinnerRoot>
                        <Styled.InitialSpinner />
                        <Label>Loading...</Label>
                    </Styled.SpinnerRoot>
                )}
                {showWebChat && (
                    <WebChat
                        userID={randomId}
                        directLine={directLine}
                        store={createStore(appContext, directLine)}
                        activityMiddleware={createActivityMiddleware(appContext)}
                        styleOptions={{ ...Styled.BotStyles, ...additionalStyles }}
                        renderMarkdown={renderAVAMarkdown}

              />
                )}
            </Styled.BotRoot>
            <Styled.Footer>
                <Link
                    styles={linkStyles}
                    href={__DPN_LINK__}
                    target="_blank_"
                    title="Microsoft Data Privacy Notice link opens in new tab"
                    aria-label="Microsoft Data Privacy Notice link opens in new tab"
                >
                    Microsoft Data Privacy Notice
                </Link>{' '}
            </Styled.Footer>
            {isTimedOut && <TimeoutOverlay />}
        </Styled.Root>
    );
}

const wrappedComponent = withContext(AskHRBot);
export { wrappedComponent as AskHRBot };
