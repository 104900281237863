import * as React from 'react';
import { createStore as createBotStore, IDirectLine, IStore, NextCard } from 'botframework-webchat';
import { actions } from './AskHRBot.actions';
import { activities } from './AskHRBot.activities';
import { IAppContext } from './Models/IAppContext';
import { ActivityType, AskHRTicketFormTrigger } from './AskHRBot.types';
import { ConversationContext } from './Context/ConversationContext';

export function createStore(appContext: IAppContext, directLine: IDirectLine): IStore {
    return createBotStore({}, ({ dispatch }) => (next) => (action) => {
        ConversationContext.setDispatch(dispatch);

        if (actions.hasOwnProperty(action.type)) {
            actions[action.type](appContext, dispatch, next, action, directLine);
            ConversationContext.trackActions(action);
        } else {
            ConversationContext.trackActions(action);
            return next(action);
        }
    });
}

export const createActivityScreenReaderMiddleware = () => (next) => () => {
    return false;
};

export function createActivityMiddleware(appContext: IAppContext) {
    return (): NextCard => (next) => (card) => {
        const activityType = card.activity.type as ActivityType;

        if (card.activity.text === AskHRTicketFormTrigger) {
            const Component = activities[ActivityType.AskHRForm](appContext, card);

            return <Component nextCard={next(card)} />;
        } else {
            if (activities.hasOwnProperty(activityType)) {
                const Component = activities[activityType](appContext, card);

                return function customActivity(children: any): React.ReactElement {
                    return <Component nextCard={next(card)}>{children}</Component>;
                };
            }
        }

        return next(card);
    };
}
